// 考勤管理
const holiday_list = '/holiday/list.do'; // 列表
const holiday_save = '/holiday/save.do'; // 保存, 修改
const holiday_input = '/holiday/input.do'; // 修改路由页
const holiday_delete = '/holiday/delete.do'; // 删除
const holiday_show = '/holiday/show.do'; // 查看
const holiday_export = '/holiday/export.do'; // 导出路由页
const holiday_exportexcel = '/holiday/exportexcel.do'; // 导出
const holiday_exportsave = '/holiday/exportsave.do'; // 导出保存
// 个人中心
const notice_list = '/notice/list.do'; // 列表、查询
const notice_approve = '/notice/approve.do'; // 去处理
const notice_list2 = '/notice/list2.do'; // 未阅读消息
const user_updatephotourl = '/user/updatephotourl.do';//更换头像
const usercard_getcard = '/usercard/getcard.do';

//其他收费
const generalmoneyiecv_list = '/generalmoneyiecv/list.do';//列表
const generalmoneyiev_inputcharges = '/generalmoneyiecv/inputcharges.do'//收款路由
const generalmoneyiecv_savecharge = '/generalmoneyiecv/savecharge.do';//收费确认
const generalmoneyiecv_checkprint = '/generalmoneyiecv/checkprint.do';//检查打印收据
const generalmoneyiecv_print = '/generalmoneyiecv/print.do';//打印收据
const generalmoneyiecv_checkapplyrefund = '/generalmoneyiecv/checkapplyrefund.do';//检查是否可以申请退费
const generalmoneyiecv_applyrefund = '/generalmoneyiecv/applyrefund.do';//申请退费路由
const studentfeeback_savegeneralmoneyiecv = '/studentfeeback/savegeneralmoneyiecv.do';//保存退费
const generalmoneyiecv_show = '/studentpotential/show.do';//查看
const studentmoneyrecv_exportstudentmoneyrecv = '/studentmoneyrecv/exportstudentmoneyrecv.do';//收费记录导出
const studentmoneyrecv_requestapprove = '/studentmoneyrecv/requestapprove.do';//提请审批
const studentmoneyrecv_approvecheck = '/studentmoneyrecv/approvecheck.do';//审核校验
const studentmoneyrecv_approvesave = '/studentmoneyrecv/approvesave.do';//审核保存
const position_positionlist = '/position/positionlist.do';//岗位列表接口

//审批人管理
const approval_list = '/approval/list.do'; //列表,查询
const approval_delete = '/approval/delete.do'; //删除
const approval_save = '/approval/save.do';//保存新增修改
const approval_input = '/approval/input.do';//修改api
//优惠卷管理
const discount_list = '/discount/list.do';//列表查询
const discount_save = '/discount/save.do';//保存
const discount_input = '/discount/input.do';//修改
const discount_delete = '/discount/delete.do';//删除
const discount_approvesave = '/discount/approvesave.do'; //审核保存接口
const discount_checkapprove = 'discount/checkapprove.do';//修改、删除 校验接口
// 收入管理
const moneyiecv_show = '/moneyiecv/show.do'; // 查看
// 学生缴费汇总
const registerfeetotal_list = '/registerfeetotal/list.do';//列表
const studentgenzong_list2 = '/studentgenzong/list2.do'; //列表

//网上报名管理
const networkregister_list = '/networkregister/list.do';//列表
const networkregister_save = '/networkregister/save.do';//保存
const networkregister_delete = '/networkregister/delete.do';//删除
const networkregister_input = '/networkregister/input.do';//修改
const networkregister_checkinput = '/networkregister/checkinput.do';//检查是否可以修改 （分配了的不能改）
const networkregister_checkmobile = '/networkregister/checkmobile.do';//检查手机号是否已经存在
const networkregister_checkqq = '/networkregister/checkqq.do';//检查qq是否已经存在
const networkregister_show = '/networkregister/show.do';//查看路由
const networkregister_checkdiscompany = '/networkregister/checkdiscompany.do';//检查分公司是否可以分配
const networkregister_discompanylist = '/networkregister/disCompanylist.do';//分配分公司路由
const networkregister_savedistocompany = '/networkregister/savedistocompany.do';//保存分配分公司
const networkregister_checkdischool = '/networkregister/checkdischool.do';//检查教学点是否可以分配    参数id   （可多选）
const networkregister_disschoollist= '/networkregister/disschoollist.do';//分配教学点路由 参数id  （可多选）
const networkregister_savedisschool = '/networkregister/savedisschool.do';//保存分配教学点
const networkregister_checkdisstudent = '/networkregister/checkdisstudent.do';//检查招生员是否可以分配
const networkregister_disstudentlist = '/networkregister/disstudentlist.do';//分配招生员路由
const networkregister_savedisstudent = '/networkregister/savedisstudent.do';//保存分配招生员
//深圳网上报名管理
const networkregister2_listz ='/networkregister2/listsz.do';//深圳列表
//分公司
const companyorg_list = '/companyorg/list.do';//列表
//分教学点
const schoolorg_list = '/schoolorg/list.do';//列表
// 收入一级项目
const moneyintype1_list = '/moneyintype1/list.do'; // 列表、查询
const moneyintype1_input = '/moneyintype1/input.do'; // 修改路由页
const moneyintype1_save = '/moneyintype1/save.do'; // 新增、修改
const moneyintype1_delete = '/moneyintype1/delete.do'; // 删除
const moneyintype1_export = '/moneyintype1/export.do'; // 导出路由页
const moneyintype1_exportexcel = '/moneyintype1/exportexcel.do'; // 导出接口
const moneyintype1_exportsave = '/moneyintype1/exportsave.do'; // 导出保存
const moneyintype1_isenabled = '/moneyintype1/isenabled.do'; // 启用、禁用
const moneyiecv_entry = '/moneyiecv/entry.do';//确定入账

// 职员管理
const user_list = '/user/list.do'; // 导出保存
const user_input = '/user/input.do'; // 修改路由页
const user_save = '/user/save.do'; // 修改, 保存
const user_delete = '/user/delete.do'; // 删除
const user_show = '/user/show.do'; // 查看
const user_setpwdsave = '/user/setpwdsave.do'; // 设置密码
const user_active = '/user/active.do'; // 激活状态
const user_unlock = '/user/unlock.do'; // 解锁
const module_treeuser = '/module/treeuser.do'; // 权限列表
const usermodule_save = '/usermodule/save.do'; // 分配权限
const roleuser_save2 = '/roleuser/save2.do'; // 分配角色
const roleuser_list3 = '/roleuser/list3.do'; // 加载角色列表
const usermove_save = '/usermove/save.do'; // 调岗、转正、离职保存
const user_input2 = '/user/input2.do'; // 调岗、转正、离职路由页
const userrenewal_list = '/userrenewal/list.do'; //续签(列表)
const userrenewal_delete = '/userrenewal/delete.do'; //续签(删除)
const userrenewal_input = '/userrenewal/input.do'; //续签(路由)
const userrenewal_save = '/userrenewal/save.do'; //续签(保存)


// 人员增补
const userapply_list = '/userapply/list.do'   // 列表
const userapply_delete = '/userapply/delete.do'   // 删除
const userapply_save = '/userapply/save.do'   // 新增, 修改保存
const userapply_input = '/userapply/input.do'   // 修改路由页
const person_list = '/person/list.do'   //查看应聘记录(保存) 传applyid
const person_input = '/person/input.do'   //查看应聘记录(修改路由页)
const person_delete = '/person/delete.do'   //查看应聘记录(删除)
const userinterview_save = '/userinterview/save.do'   //保存-应聘记录(预约面试)
const userinterview_list = '/userinterview/list.do'   //查看-预约信息
const userinterview_delete = '/userinterview/delete.do'   //删除-预约信息
const userinterview_flag = '/userinterview/flag.do'   //设置-预约信息

const person_save = '/person/save.do'   //上传应聘资料
const userapply_approvesave = '/userapply/approvesave.do'   //审批保存
const userapply_requestapprove = '/userapply/requestapprove.do'   //提请审批




// 会议管理
const meeting_list = '/meeting/list.do'; // 列表, 搜索
const meeting_delete = '/meeting/delete.do'; // 删除
const meeting_save = '/meeting/save.do'; // 添加, 修改保存
const meeting_input = '/meeting/input.do'; // 修改路由页



// 员工团建, 学术交流
const building_save = '/building/save.do'; // 新增保存
const building_delete = '/building/delete.do'; // 删除
const building_list = '/building/list.do'; // 列表, 搜索
const building_input = '/building/input.do'; // 修改路由页

// 每日封面
const dailycover_save = '/dailycover/save.do'; // 新增保存
const dailycover_delete = '/dailycover/delete.do'; // 删除
const dailycover_list = '/dailycover/list.do'; // 列表, 搜索
const dailycover_input = '/dailycover/input.do'; // 修改路由页



// 师生风采
const elegant_save = '/elegant/save.do'; // 新增保存
const elegant_delete = '/elegant/delete.do'; // 删除
const elegant_list = '/elegant/list.do'; // 列表, 搜索
const elegant_input = '/elegant/input.do'; // 修改路由页

//业务培训
const businessvideo_list = '/businessvideo/list.do'; //列表
const businessvideo_save = '/businessvideo/save.do'; //提交
const businessvideo_input = '/businessvideo/input.do'; //修改
const businessvideo_delete = '/businessvideo/delete.do';//删除

// 五星员工
const fivestars_save = '/fivestars/save.do'; // 新增保存
const fivestars_delete = '/fivestars/delete.do'; // 删除
const fivestars_list = '/fivestars/list.do'; // 列表, 搜索
const fivestars_input = '/fivestars/input.do'; // 修改路由页




//--教材订购
const goodsregister_list = '/goodsregister/list.do'; //列表、查询
const goodsregister_checkedit = '/goodsregister/checkedit.do'; //修改校验
const goodsregister_input2 = '/goodsregister/input2.do'; //修改路由页
const goodsregister_save = '/goodsregister/save.do'; //新增、修改
const goodsregister_delete = '/goodsregister/delete.do'; //删除
const goodsregister_isok = '/goodsregister/isok.do'; //确认制单
const goodsregister_cancelisok = '/goodsregister/cancelisok.do'; //取消制单
const goodsregister_checkprint = '/goodsregister/checkprint.do'; //打印校验
const goodsregister_print = '/goodsregister/print.do'; //打印
const goodsregister_exportbyid = '/goodsregister/exportbyid.do'; //导出订单
const goodsregister_exports = '/goodsregister/exports.do'; //导出总表

//--库存管理
const stockmanage_list = '/stockmanage/list.do'; //列表、查询
const stockmanage_input = '/stockmanage/input.do'; //修改、入库、领用、盘点路由页
const stockmanage_save = '/stockmanage/save.do'; //新增、修改
const stockmanage_delete = '/stockmanage/delete.do'; //删除
const stockmanage_export = '/stockmanage/export.do'; //导出路由页
const stockmanage_exportexcel = '/stockmanage/exportexcel.do'; //导出
const stockmanage_exportsave = '/stockmanage/exportsave.do'; //导出保存
const warehousing_save = '/warehousing/save.do'; //入库
const collect_save = '/collect/save.do'; //领取
const inventory_save = '/inventory/save.do'; //盘点
const stockmanage_importsave = '/stockmanage/importsave.do'; //导入

//--入库记录
const warehousing_list = '/warehousing/list.do'; //列表、查询
const warehousing_export = '/warehousing/export.do'; //导出路由页
const warehousing_exportexcel = '/warehousing/exportexcel.do'; //导出
const warehousing_exportsave = '/warehousing/exportsave.do'; //导出保存

//--领取记录
const collect_list = '/collect/list.do'; //列表、查询
const collect_export = '/collect/export.do'; //导出路由页
const collect_exportexcel = '/collect/exportexcel.do'; //导出
const collect_exportsave = '/collect/exportsave.do'; //导出保存

//--盘点记录
const inventory_list = '/inventory/list.do'; //列表、查询
const inventory_export = '/inventory/export.do'; //导出路由页
const inventory_exportexcel = '/inventory/exportexcel.do'; //导出
const inventory_exportsave = '/inventory/exportsave.do'; //导出保存

//--办公用品采购计划
const purchase_list = '/purchase/list.do'; //列表、查询
const purchase_input = '/purchase/input.do'; //修改路由页
const purchase_save2 = '/purchase/save2.do'; //快速新增
const purchase_delete = '/purchase/delete.do'; //删除
const purchase_requestapprove = '/purchase/requestapprove.do'; //提请审批
const purchase_approvesave = '/purchase/approvesave.do'; //负责人审批
const approve_list = '/approve/list.do'; //查看审批
const purchase_print = '/purchase/print.do'; //打印
const purchasedetail_exportdetail = '/purchasedetail/exportdetail.do'; //导出详情

//--办公用品采购详情
const purchasedetail_list = '/purchasedetail/list.do'; //列表、查询
const purchasedetail_input = '/purchasedetail/input.do'; //修改路由页
const purchasedetail_save = '/purchasedetail/save.do'; //修改
const purchasedetail_delete = '/purchasedetail/delete.do'; //删除


//--日程管理
const schedulemanage_list2 = '/schedulemanage/list2.do'; //日历显示接口： （包含个人日程、总部管理的年度日程）
const schedulemanage_save = '/schedulemanage/save.do'; //新增、修改
const schedulemanage_delete = '/schedulemanage/delete.do'; //删除

//--年度日程
const scheduleyear_save = '/scheduleyear/save.do'; //新增
const scheduleyear_list = '/scheduleyear/list.do'; //列表
const schedulemanagedetail_list = '/schedulemanagedetail/list.do'; //查看详情
const scheduleyear_delete = '/scheduleyear/delete.do'; //删除
const schedulemanagedetail_save = '/schedulemanagedetail/save.do'; //详情新增，修改
const schedulemanagedetail_delete = '/schedulemanagedetail/delete.do'; //详情删除

//请假管理
const askleave_list = '/askleave/list.do'; //列表、查询
const askleave_input = '/askleave/input.do'; //修改路由页
const askleave_save = '/askleave/save.do'; //新增、修改
const askleave_delete = '/askleave/delete.do'; //删除
const askleave_requestapprove = '/askleave/requestapprove.do'; //提请审批
const askleave_approvecheck = '/askleave/approvecheck.do';//审批检查
const askleave_approvesave = '/askleave/approvesave.do'; //审批保存

//学历转介绍或优惠奖励标准
const referral_list = '/referral/list.do'; //列表、查询
const referral_input = '/referral/input.do'; //修改路由页
const referral_save = '/referral/save.do'; //新增、修改
const referral_delete = '/referral/delete.do'; //删除
const referral_importsave = '/referral/importsave.do'; //导入

//会计、教师资格奖励标准
const accountingreward_list = '/accountingreward/list.do'; //列表、查询
const accountingreward_input = '/accountingreward/input.do'; //修改路由页
const accountingreward_save = '/accountingreward/save.do'; //新增、修改
const accountingreward_delete = '/accountingreward/delete.do'; //删除


//执业药师奖励标准
const pharmacist_list = '/pharmacist/list.do'; //列表、查询
const pharmacist_input = '/pharmacist/input.do'; //修改路由页
const pharmacist_save = '/pharmacist/save.do'; //新增、修改
const pharmacist_delete = '/pharmacist/delete.do'; //删除

//活动奖励
const activity_list = '/activity/list.do'; //列表、查询
const activity_input = '/activity/input.do'; //修改路由页
const activity_save = '/activity/save.do'; //新增、修改
const activity_delete = '/activity/delete.do'; //删除


//学历招生保底计划
const guarantee_list = '/guarantee/list.do'; //列表、查询
const guarantee_input = '/guarantee/input.do'; //修改路由页
const guarantee_save = '/guarantee/save.do'; //新增、修改
const guarantee_delete = '/guarantee/delete.do'; //删除

//执业药师目标达成奖励统计
const educationreward_listzhiye = '/educationreward/listzhiye.do'; //列表、查询

//成考、网教、自考目标达成奖
const educationreward_list = '/educationreward/list.do'; //列表、查询

//会计、教师资格目标达成奖励统计
const educationreward_listkuaiji = '/educationreward/listkuaiji.do'; //会计列表
const educationreward_listjiaoshi = '/educationreward/listjiaoshi.do'; //教师资格列表

//团队突出贡献奖励标准
const team_list = '/team/list.do'; //列表
const team_input = '/team/input.do'; //修改路由页
const team_save = '/team/save.do'; //新增、修改
const team_delete = '/team/delete.do'; //删除

//个人突出贡献奖励标准
const personal_list = '/personal/list.do'; //列表
const personal_input = '/personal/input.do'; //修改路由页
const personal_save = '/personal/save.do'; //新增、修改
const personal_delete = '/personal/delete.do'; //删除
// 网课管理
const videocourse_list = '/videocourse/list.do';//列表
const videocourse_save = '/videocourse/save.do';//保存
const videocourse_input = 'videocourse/input.do'; //改课路由
const videocourse_delete = '/videocourse/delete.do'; //删除
const videocoursedetiled_list = '/videocoursedetailed/list.do'; //详情列表
const videocoursedetailed_getchapter = '/videocoursedetailed/getchapter.do'; //获取章节
const videocoursedetailed_savechapter = '/videocoursedetailed/savechapter.do'; //新增章节
const videocoursedetialed_save = '/videocoursedetailed/save.do'; //新增小节
const videocoursedetailed_input = '/videocoursedetailed/input.do'; //修改小节路由
const videocoursedetailsed_delte = '/videocoursedetailed/delete.do'; //删除
const videocoursedetailed_importsave = '/videocoursedetailed/importsave.do'; //导入
//题库管理
const question_list = '/question/list.do';//列表
const question_save = '/question/save.do'; //提交
const question_input = '/question/input.do'; //提交
const question_delete = '/question/delete.do'; //删除
const questiondetailed_list = '/questiondetailed/list.do'; //详情
const questiondetailed_save= '/questiondetailed/save.do'; //详情提交
const questiondetailed_input = '/questiondetailed/input.do'; //修改详情
const questiondetailed_delete = '/questiondetailed/delete.do'; //
const questiondetailed_importsave = '/questiondetailed/importsave.do';//导入

//考前管理
const studentuser_list = 'studentuser/list.do'; //列表
const studentuser_input = 'studentuser/input.do'; //改课路由
const studentuser_savestudent = 'studentuser/savestudent.do'; //改课保存
const studentuser_saveisavailable = 'studentuser/saveisavailable.do'; //是否禁用
const studentuser_saveisintergrity = 'studentuser/saveisintegrity.do'; //是否可编辑
//院校收入管理
const collegesincome_list = '/collegesincome/list.do'; //列表、查询
const collegesincome_input = '/collegesincome/input.do'; //修改路由页
const collegesincome_save = '/collegesincome/save.do'; //新增、修改
const collegesincome_delete = '/collegesincome/delete.do'; //删除
const collegesincome_export = '/collegesincome/export.do'; //导出路由页
const collegesincome_exportexcel = '/collegesincome/exportexcel.do'; //导出
const collegesincome_exportsave = '/collegesincome/exportsave.do'; //导出保存
const collegesincome_importsave = '/collegesincome/importsave.do'; //导入

//学历继续教育
const student_input2 = '/student/input2.do';
const student_save2 = '/student/save2.do';
const student_list2 = '/student/list2.do';//列表
const student_importsave = '/student/importsave.do'; //学生核准
const student_list = '/student/list.do'; //列表、查询
const student_input = '/student/input.do'; //修改路由页
const student_show = '/student/show.do'; //查看
const student_save = '/student/save.do'; //新增、修改
const student_delete = '/student/delete.do'; //删除
const student_checkincollect = '/student/checkincollect.do'; //检查是否能收费
const student_incollect = '/student/incollect.do'; //收费路由页
const student_savecollect = '/student/savecollect.do'; //收费
const student_checkprintshouju = '/student/checkprintshouju.do'; //检查可否打印
const student_printshouju = '/student/printshouju.do'; //打印收据
const student_printshoujuPaper = '/student/printshoujuPaper.do'; //打印收据纸质
const student_checkapplyrefund = '/student/checkapplyrefund.do'; //检查是否可以申请退费
const student_applyrefund = '/student/applyrefund.do'; //申请退费路由页
const studentfeeback_savestudent = '/studentfeeback/savestudent.do'; //保存申请退费
const student_changeflag = '/student/changeflag.do'; //变更状态
const studentpotential_show = '/studentpotential/show.do'; //查看
const studentbook_checkprint = '/studentbook/checkprint.do'; //检查可否打印
const studentbook_print = '/studentbook/print.do'; //打印收据
const studentbook_printshoujuPaper = '/studentbook/printshoujuPaper.do'; //打印收据
const studentregister_show2 = '/studentregister/show2.do';//学籍查看
const student_export = '/student/export.do';//导出路由
const student_exportexcel = '/student/exportexcel.do';//导出
const student_exportsave = 'student/exportsave.do';//保存
const student_importsave2 = '/student/importsave2.do'; //导出
const student_exportstudet2 = '/student/exportstudent2.do'; //导入
const student_input3 = 'student/input3.do'; //确认&开课 路由
const videocourse_dialogresult = 'videocourse/dialogresult.do'; //课程
const question_dialogresult = 'question/dialogresult.do'; //题库

//代预报名管理
const studentyubaoming_list = '/studentyubaoming/list.do'; //列表、查询
const studentyubaoming_delete = '/studentyubaoming/delete.do'; //删除
const studentyubaoming_input = '/studentyubaoming/input.do'; //修改路由页
const studentyubaoming_save = '/studentyubaoming/save.do'; //新增、修改
const studentyubaoming_checkapplyrefund = '/studentyubaoming/checkapplyrefund.do'; //检查是否可以申请退费
const studentyubaoming_applyrefund = '/studentyubaoming/applyrefund.do'; //申请退费
const studentfeeback_savestudentyubaoming = '/studentfeeback/savestudentyubaoming.do'; //保存退费
const studentyubaoming_checkincollect = '/studentyubaoming/checkincollect.do'; //检查是否能收费
const studentyubaoming_incollect = '/studentyubaoming/incollect.do'; //加载收费
const studentyubaoming_savecollect = '/studentyubaoming/savecollect.do'; //收费
const studentyubaoming_checkprint = '/studentyubaoming/checkprint.do'; //检查可否打印
const studentyubaoming_printshouju = '/studentyubaoming/printshouju.do'; //打印收据
const studentyubaoming_printshoujuPaper = '/studentyubaoming/printshoujuPaper.do'; //打印收据纸质
const studentyubaoming_exportexcel = '/studentyubaoming/exportexcel.do';//导出取消
const studentyubaoming_export = '/studentyubaoming/export.do';//导出
const studentyubaoming_export2 = '/studentyubaoming/export2.do'; //导出
const studentyubaoming_exportsave = '/studentyubaoming/exportsave.do';//导出保存

//学历辅导班管理
const studentfudao_list = '/studentfudao/list.do'; //列表、查询
const studentfudao_delete = '/studentfudao/delete.do'; //删除
const studentfudao_input = '/studentfudao/input.do'; //修改路由页
const studentfudao_save = '/studentfudao/save.do'; //新增、修改
const studentfudao_show = '/studentfudao/show.do'; //查看
const studentfudao_canfeeback = '/studentfudao/canfeeback.do'; //检查是否可以申请退费
const studentfudao_feeback = '/studentfudao/feeback.do'; //申请退费
const studentfeeback_savestufudaofeeback = 'studentfeeback/savestufudaofeeback.do' //退费保存
const studentfudao_canenrol = '/studentfudao/canenrol.do' //检查是否可以收费
const studentfudao_enrol = '/studentfudao/enrol.do'; //收费
const studentfudao_saveenrol = '/studentfudao/saveenrol.do'; //保存收费
const studentfudao_checkprint = '/studentfudao/checkprint.do'; //检查可否打印
const studentfudao_print = '/studentfudao/print.do'; //打印收据
const studentfudao_printshoujuPaper= '/studentfudao/printshoujuPaper.do'; //打印收据
const studentfudao_export1 = '/studentfudao/export1.do';//导出
//教师资格培训
const techfudao_list = '/techfudao/list.do'; //列表、查询
const techfudao_listjn = '/techfudao/listjn.do';//列表
const techfudao_delete = '/techfudao/delete.do'; //删除
const generalmoneyiecv_delete = '/generalmoneyiecv/delete.do';//删除
const techfudao_input = '/techfudao/input.do'; //修改路由页
const techfudao_save = '/techfudao/save.do'; //新增、修改
const techfudao_show = '/techfudao/show.do'; //查看
const techfudao_canfeeback = '/techfudao/canfeeback.do'; //检查是否可以申请退费
const techfudao_feeback = '/techfudao/feeback.do'; //申请退费
const techfeeback_savetechfudaofeeback = '/techfeeback/savetechfudaofeeback.do'; //保存申请退费
const techfudao_canenrol = '/techfudao/canenrol.do' //检查是否可以收费
const techfudao_enrol = '/techfudao/enrol.do' //收费
const techfudao_saveenrol = '/techfudao/saveenrol.do' //保存收费
const techfudao_canextrafee = '/techfudao/canextrafee.do' //判断是否额外减免
const techfudao_checkprint = '/techfudao/checkprint.do'; //检查可否打印
const techfudao_print = '/techfudao/print.do'; //打印收据
const techfudao_printshoujuPaper = '/techfudao/printshoujuPaper.do'; //打印收据
const techfudao_export2 = '/techfudao/export2.do';//导出
const techfudao_export3 = '/techfudao/export3.do';//导出
const teachfudao_arrearslist = '/techfudao/arrearslist.do';//列表
const techfudao_savearrearscollect = '/techfudao/savearrearscollect.do';//保存
const techfudao_export4 = '/techfudao/export4.do';//导出
const techfudao_distribution = '/techfudao/distribution.do'; //保存
const techclass_dialogresult = '/techclass/dialogresult.do'; //选择班级
//业务活动
const business_list = '/business/list.do'; //列表、查询
const business_input = '/business/input.do'; //修改路由页
const business_delete = '/business/delete.do'; //删除
const business_save = '/business/save.do'; //新增、修改

//院校往来应收结转
const collegespay_paylist = '/collegesreceivable/list2.do';//列表
const collegesreceivable_incomelist = 'collegesreceivable/incomelist.do';//应收结转的收费记录接口
//院校往来应付结转
const colegespay_listpay = '/collegespay/list2.do';//列表
//院校往来应收
const collegesrceivable_checkprintshouju = 'collegesreceivable/checkprintshouju.do'// 检查是否可以打印
const collegesreceivable_printrsceipt = 'collegesreceivable/print.do'//打印收据
const studentregister_dialogresultselect = 'studentregister/dialogresult4.do';//选择学生
const collegesreceivable_save  = 'collegesreceivable/save2.do';//新增保存
const collegesreceivable_list = '/collegesreceivable/list.do'; //列表、查询
const collegesreceivable_input = '/collegesreceivable/input.do'; //路由页
const collegesreceivable_importance = '/collegesreceivable/importsave2.do';//返还款
const collegesreceivable_improtdo = '/collegesreceivable/importsave3.do';//总部管理费
const collegesreceivabel_import = '/collegesreceivable/importsave4.do';//预留收费项
const collegesreceivable_importsave = '/collegesreceivable/importsave.do'; //导入
const collegesreceivabledetail_list = '/collegesreceivabledetail/list.do'; //详情列表
const collegesreceivable_exportdetail = '/collegesreceivable/exportdetail.do'; //导出
const collegesreceivabledetail_save2 = '/collegesreceivabledetail/save2.do'; //查看名单 - 保存
const collegesreceivable_income = '/collegesreceivable/income.do'; //收款路由
const collegesreceivabledetail_income = '/collegesreceivabledetail/income.do'; //查看名单 - 收款路由
const collegesreceivabledetail_incomesave = '/collegesreceivabledetail/incomesave.do'; //查看名单 - 收款
const collegesreceivabel_income_save = '/collegesreceivable/incomesave.do';//往来收款
const collegesreceivablefee_list = '/collegesreceivablefee/list.do'; //收费记录 - 列表
const collegesreceivable_printshoujuPaper = '/collegesreceivable/printshoujuPaper.do';//纸质打印：

//院校往来应付
const collegespay_list = '/collegespay/list.do'; //列表、查询
const collegespay_input = '/collegespay/input.do'; //路由页
const collegespaydetail_list = '/collegespaydetail/list.do'; //详情列表
const collegespay_exportdetail = '/collegespay/exportdetail.do'; //导出
const collegespaydetail_save2 = '/collegespaydetail/save2.do'; //查看名单 - 保存
const collegespaydetail_pay = '/collegespaydetail/pay.do'; //查看名单 - 付款路由
const collegespaydetail_paysave = '/collegespaydetail/paysave.do'; //查看名单 - 付款保存
const collegespayfee_list = '/collegespayfee/list.do'; //付款记录 - 列表
const collegespay_pay ='/collegespay/pay.do';//付款
const conllespay_paysave  = '/collegespay/paysave.do'//付款保存
const collegespay_approvecheck = '/collegespay/approvecheck.do'; //审批检查
const collegespay_approvesave = '/collegespay/approvesave.do'; //审批保存
const collegespay_paylistdo = '/collegespay/paylist.do';//应付结转 付款记录接口：
//日常往来应收
const dailyreceivable_checkprintshouju = 'dailyreceivable/checkprintshouju.do'; //检查是否可以打印
const dailyreceivable_print = 'dailyreceivable/print.do';//打印收据
const dailyreceivable_printshoujuPaper = 'dailyreceivable/printshoujuPaper.do';//打印收据
const dailyreceivable_list = '/dailyreceivable/list.do'; //列表、查询
const dailyreceivable_saveall = '/dailyreceivable/saveall.do'; //快速新增
const dailyreceivable_income = '/dailyreceivable/income.do'; //收款路由页
const dailyreceivable_incomesave = '/dailyreceivable/incomesave.do'; //收款保存
const dailyreceivable_delete = '/dailyreceivable/delete.do'; //删除
const dailyreceivabledetail_list = '/dailyreceivabledetail/list.do'; //查看详情
const dailyreceivabledetail_save = '/dailyreceivabledetail/save.do'; //修改（详情）
const dailyreceivabledetail_input = '/dailyreceivabledetail/input.do'; //修改路由页（详情）
const dailyreceivabledetail_delete = '/dailyreceivabledetail/delete.do'; //删除（详情）
const dailyreceivable_requestbad = '/dailyreceivable/requestbad.do'; //申请坏账
const dailyreceivable_bad = '/dailyreceivable/bad.do'; //坏账处理
const dailyreceivable_incomelist = '/dailyreceivable/incomelist.do';//收费记录
const dailyreceivabledetail_check = '/dailyreceivabledetail/check.do';//日常往来应收详情，修改校验

//日常往来应付
const dailypay_list = '/dailypay/list.do'; //列表、查询
const dailypay_saveall = '/dailypay/saveall.do'; //快速新增
const dailypay_delete = '/dailypay/delete.do'; //删除
const dailypay_approvecheck = '/dailypay/approvecheck.do'; //审批检查
const dailypay_approvesave = '/dailypay/approvesave.do'; //审批保存
const dailypay_pay = '/dailypay/pay.do'; //付款路由页
const dailypay_paysave = '/dailypay/paysave.do'; //付款保存
const dailypaydetail_list = '/dailypaydetail/list.do'; //查看详情
const dailypaydetail_save = '/dailypaydetail/save.do'; //修改（详情）
const dailypaydetail_input = '/dailypaydetail/input.do'; //修改路由页（详情）
const dailypaydetail_delete = '/dailypaydetail/delete.do'; //删除（详情）
const dailypay_requestbad = '/dailypay/requestbad.do'; //申请坏账
const dailypay_bad = '/dailypay/bad.do'; //坏账处理
const dailypaydetail_check ='dailypaydetail/check.do';//日常往来应收详情，修改校验

//往来汇总
const summary_list = '/summary/list.do'; //列表

//坏账管理
const baddebt_list = '/baddebt/list.do'; //列表


//招生汇总图表
const performance_chengkao = '/performance/chengkao.do'; //各教学点招生汇总

//成考招生精英
const performance_chengkaopersonal = '/performance/chengkaopersonal.do'; //成考招生精英

//市场部业绩汇总
const performance_market = '/performance/market.do'; //市场部业绩汇总

//客服业绩汇总
const performance_kefu = '/performance/kefu.do'; //客服业绩汇总

// 互动美语业绩汇总
const techperformance_meiyuzhaosheng = '/techperformance/meiyuzhaosheng.do'; //客服业绩汇总


//退费管理
const studentfeeback_list = '/studentfeeback/list.do'; //列表
const studentfeeback_approvecheck = '/studentfeeback/approvecheck.do'; //审批检查
const studentfeeback_approvesave = '/studentfeeback/approvesave.do'; //保存审批
const studentfeeback_export = '/studentfeeback/export.do'; //导出路由页
const studentfeeback_exportexcel = '/studentfeeback/exportexcel.do'; //导出
const studentfeeback_exportsave = '/studentfeeback/exportsave.do'; //导出保存
const studentmoneybackrecv_canbatchmoneybackrecv = '/studentmoneybackrecv/canbatchmoneybackrecv.do'; //退费校验（退费、批量退费校验共用）
const studentmoneybackrecv_input = '/studentmoneybackrecv/input.do'; //退费路由页
const studentmoneybackrecv_save = '/studentmoneybackrecv/save.do'; //退费
const studentmoneybackrecv_inputbatch = '/studentmoneybackrecv/inputbatch.do'; //批量退费路由页
const studentmoneybackrecv_savebatch = '/studentmoneybackrecv/savebatch.do'; //批量退费
const studentmoneybackrecv_list = '/studentmoneybackrecv/list.do'; //查看退费记录
const studentfeeback_delete = '/studentfeeback/delete.do'; //删除
const studentfeeback_exportfeeback = '/studentfeeback/exportfeeback.do';//导出

//技能班别管理
const fudaoclasstype_list2 = '/fudaoclasstype/list2.do'; //列表
const fudaoclasstype_save2 = '/fudaoclasstype/save2.do'; //新增

//班级管理
const techclass_list = '/techclass/list.do'; //列表
const techclass_save = '/techclass/save.do'; //新增
const techclass_delete = '/techclass/delete.do'; //删除
const techclass_list2 = '/techclass/list2.do'; //查看

//教学计划
const techteachingplan_list = '/techteachingplan/list.do'; //列表
const techteachingplan_input = '/techteachingplan/input.do'; //修改路由页
const techteachingplan_save = '/techteachingplan/save.do'; //新增、修改
const techteachingplan_delete = '/techteachingplan/delete.do'; //删除

//教师管理
const techteacher_list = '/techteacher/list.do'; //列表
const techteacher_input = '/techteacher/input.do'; //修改路由页
const techteacher_save = '/techteacher/save.do'; //新增、修改
const techteacher_delete = '/techteacher/delete.do'; //删除
const techteacher_export = '/techteacher/export.do'; //导出路由页
const techteacher_exportexcel = '/techteacher/exportexcel.do'; //导出接口
const techteacher_exportsave = '/techteacher/exportsave.do'; //导出保存
const techteacherdetail_save = '/techteacherdetail/save.do'; //上传凭证
const techteacherdetail_input = '/techteacherdetail/input.do'; //详情的修改路由页
const techteacherdetail_delete = '/techteacherdetail/delete.do'; //查看详情删除
const techteacherdetail_list = '/techteacherdetail/list.do'; //查看详情
const techfudao_inarrearscollect = '/techfudao/inarrearscollect.do';//收款路由

//现金初始配置
const account_listcash = '/account1/list.do'; //列表
const account_inputcash = '/account/inputcash.do'; //修改路由页
const account_savecash = '/account/savecash.do'; //新增、修改
const account_delete = '/account/delete.do'; //删除
const account_requestapprove = '/account/requestapprove.do'; //提请审批
const account_approvecheck = '/account/approvecheck.do'; //审批检查
const account_approvesave = '/account/approvesave.do'; //审批保存
const account_export = '/account/export.do'; //导出路由页
const account_exportexcel = '/account/exportexcel.do'; //导出
const account_exportsave = '/account/exportsave.do'; //导出保存


//银行账户初始配置
const account_listbank = '/account2/list.do'; //列表、查询
const account_inputbank = '/account/inputbank.do'; //修改路由页
const account_savebank = '/account/savebank.do'; //新增、修改
const bankaccountchange_input = '/bankaccountchange/input.do'; //变更路由页
const bankaccountchange_save = '/bankaccountchange/save.do'; //变更保存
const bankaccountchange_list = '/bankaccountchange/list.do'; //变更记录
const studentnal_list = '/studentpotential/list.do';//列表
// 资源库
const studentpotential_export = '/studentpotential/export.do'; //导出
const studentpotential_importsave = 'studentpotential/importsave.do'; //导出
const studentpotential_list = '/studentpotential/signuplist.do'; //列表、查询
const studentpotential_input = '/studentpotential/input.do'; //修改路由页
const studentpotential_save = '/studentpotential/save.do'; //新增、修改
const studentgenzong_save = '/studentgenzong/save.do'; //跟进 （增加或修改）
const studentgenzong_input = '/studentgenzong_input.do'; //跟进 （增加或修改）
const studentgenzong_list = '/studentgenzong/list.do'; //跟进详情 列表
const studentbook_saveall = '/studentbook/saveall.do'; //买教材
const student_addstudentinput = '/student/addstudentinput.do'; //报学历路由
const studentbook_inputall = '/studentbook/inputall.do'; //购买教材
const studentpotential_changefollow = '/studentpotential/changefollow.do'; // 分配老师路由页
const studentpotential_savechangefollow = '/studentpotential/savechangefollow.do'; // 分配老师保存
const generalmoneyiecv_moneyiecvlist = '/generalmoneyiecv/moneyiecvlist.do'; //收费路由
const student_saveinfo = '/student/saveinfo.do'//保存资料下一步
const generalmoneyiecv_input = '/generalmoneyiecv/input.do';//新增报其他
const generalmonyiecv_save = '/generalmoneyiecv/save.do'; //保存报其他
// 汇总总表
const studentdeli_exportchengkao = '/studentdeli/exportchengkao.do'; //收费路由
const generalmoneyiecv_export2 = '/generalmoneyiecv/export2.do'; //导出

// 学生学习状态
const studentstudy_list = '/studentstudy/list.do'; //列表、查询
const studentstudy_input = '/studentstudy/input.do'; //修改路由页
const studentstudy_save = '/studentstudy/save.do'; //新增、修改
const studentstudy_delete = '/studentstudy/delete.do'; //删除接口
const studentstudy_export = '/studentstudy/export.do'; //导出路由页
const studentstudy_exportexcel = '/studentstudy/exportexcel.do'; //导出接口
const studentstudy_exportsave = '/studentstudy/exportsave.do'; //导出保存
const studentstudy_importsave = '/studentstudy/importsave.do'; //导入
const techfudao_dialogresult = '/techfudao/dialogresult.do'; //技能学生列表
const techfudao_gettechfudao = '/techfudao/gettechfudao.do'; //获取技能学生

// 考试成绩管理
const techexam_list = '/techexam/list.do'; //列表、查询
const techexam_input = '/techexam/input.do'; //修改路由页
const techexam_save = '/techexam/save.do'; //新增、修改
const techexam_delete = '/techexam/delete.do'; //删除
const techexam_importsave = '/techexam/importsave.do'; //导入名单接口
const techexamdetail_list = '/techexamdetail/list.do'; //查看详情接口
const techexamdetail_importsave = '/techexamdetail/importsave.do'; //导入成绩
const techexamdetail_exportscore = '/techexamdetail/exportscore.do'; //导出成绩
const techexamdetail_input = '/techexamdetail/input.do'; //修改路由页
const techexamdetail_save = '/techexamdetail/save.do'; //增加详情
const techexamdetail_delete = '/techexamdetail/delete.do'; //删除
const educationexam_importsave2 = '/educationexam/importsave2.do'; //导入批量

// 考试成绩管理
const techcertificate_list = '/techcertificate/list.do'; //列表、查询
const techcertificate_input = '/techcertificate/input.do'; //修改路由页
const techcertificate_save = '/techcertificate/save.do'; //新增、修改
const techcertificate_delete = '/techcertificate/delete.do'; //删除

// 未取得证书人员管理
const technocertificate_list = '/technocertificate/list.do'; //列表、查询
const technocertificate_input = '/technocertificate/input.do'; //修改路由页
const technocertificate_save = '/technocertificate/save.do'; //新增、修改
const technocertificate_delete = '/technocertificate/delete.do'; //删除
const technocertificate_isflag = '/technocertificate/isflag.do'; //标记状态
const technocertificate_importsave = '/technocertificate/importsave.do'; //导入接口
const technocertificate_export = '/technocertificate/export.do'; //导出路由页
const technocertificate_exportexcel = '/technocertificate/exportexcel.do'; //导出接口
const technocertificate_exportsave = '/technocertificate/exportsave.do'; //导出保存

// 院校管理
const college_list = '/college/list.do'; //列表、查询
const college_input = '/college/input.do'; //修改路由页
const college_show = '/college/show.do'; //查看
const college_save = '/college/save.do'; //新增、修改
const college_delete = '/college/delete.do'; //删除
const college_export = '/college/export.do'; //导出路由页
const college_exportexcel = '/college/exportexcel.do'; //导出接口
const college_exportsave = '/college/exportsave.do'; //导出保存
const college_importsave = '/college/importsave.do'; //导入保存

//组卷管理
const examsettings_list = '/examsettings/list.do'; //列表
const question_dialogresult2 = '/question/dialogresult2.do'; //选择题库
const examsettings_delete = '/examsettings/delete.do'; //删除
const examsettings_save = '/examsettings/save.do'; //保存
const examsettings_input = '/examsettings/input.do'; //修改
const examsettingsdetailed_list = '/examsettingsdetailed/list.do'; //题库列表
const examsettingsdetailed_save = '/examsettingsdetailed/save.do'; //新增、修改
const examsettingsdetailed_input = '/examsettingsdetailed/input.do'; //修改
const examsettingsdetailed_delete = '/examsettingsdetailed/delete.do'; //删除

//业务考核
const businessexams_list = '/businessexams/list.do'; //列表
const businessexams_save = '/businessexams/save.do'; //保存
const examsettings_dialogresult = '/examsettings/dialogresult.do';
const businessexams_input = '/businessexams/input.do';
const businessexams_delete = '/businessexams/delete.do';
const businessexams_show = '/businessexams/show.do';
const businessexamsdetailed_start = '/businessexamsdetailed/start.do';
const businessexamsdetailed_submit = '/businessexamsdetailed/submit.do'; //交卷
const businessexamsdetailed_score = '/businessexamsdetailed/score.do'; //改卷
// 院校专业管理
const collegemajor_list = '/collegemajor/list.do'; //列表、查询
const collegemajor_list2 = '/collegemajor/list2.do'; //列表、查询
const collegemajor_input = '/collegemajor/input.do'; //修改路由页
const collegemajor_save = '/collegemajor/save.do'; //新增、修改
const collegemajor_delete = '/collegemajor/delete.do'; //删除
const collegemajor_export = '/collegemajor/export.do'; //导出路由页
const collegemajor_exportexcel = '/collegemajor/exportexcel.do'; //导出接口
const collegemajor_exportsave = '/collegemajor/exportsave.do'; //导出保存
const college_dialogresult = '/college/dialogresult.do'; //院校列表
const college_getcollegeinfo = '/college/getcollegeinfo.do'; //获取院校
const collegemajor_importsave = '/collegemajor/importsave.do'; //导入
const collegemajor_show = '/collegemajor/show.do'; //详情

// 教学点学生转出，入
const studentmove_list = '/studentmove/list.do'; //列表、查询
const studentpotential_dialogresult2 = '/studentpotential/dialogresult2.do';//选择学生列表
const studentregister_dialogresult4 = '/studentregister/dialogresult4.do'; //选择学生列表
const studentregister_dialogresult = '/studentpotential/dialogresult.do'; //选择学生列表
const studentregister_getstudentregister1 = '/studentregister/getstudentregister1.do'; //获取学生信息
const vschoolorg_dialogresult = '/vschoolorg/dialogresult.do'; //教学点列表
const vschoolorg_getvschoolorg = '/vschoolorg/getvschoolorg.do'; //获取教学点
const studentmove_save = '/studentmove/save.do'; //新增、修改接口
const studentmove_input = '/studentmove/input.do'; //修改路由页
const studentmove_delete = '/studentmove/delete.do'; //删除
const studentmove_export = '/studentmove/export.do'; //导出路由页
const studentmove_exportexcel = '/studentmove/exportexcel.do'; //导出接口
const studentmove_exportsave = '/studentmove/exportsave.do'; //导出保存
const studentmove_batchsaverecv = '/studentmove/batchsaverecv.do'; //批量接收
const studentmove_recvlist = '/studentmove/recvlist.do'; //教学点学生转入列表
const studentmove_export1 = '/studentmove/export1.do'//新接口：转出-导出
const studentmove_export2 = '/studentmove/export2.do'//新接口：转入-导出

// 学历毕业证书管理
const diploma_list = '/diploma/list.do'; //列表、查询
const diploma_input = '/diploma/input.do'; //修改路由页
const diploma_save = '/diploma/save.do'; //新增、修改
const diploma_delete = '/diploma/delete.do'; //删除

// 试卷库
const paperstock_list = '/paperstock/list.do'; //列表、查询
const paperstock_input = '/paperstock/input.do'; //修改路由页
const paperstock_save = '/paperstock/save.do'; //新增、修改
const paperstock_delete = '/paperstock/delete.do'; //删除

// 异动管理
const transaction_list = '/transaction/list.do'; //列表、查询
const transaction_input = '/transaction/input.do'; //修改路由页
const transaction_save = '/transaction/save.do'; //新增、修改
const transaction_delete = '/transaction/delete.do'; //删除
const transaction_approve2 = '/transaction/approve2.do'; //办理结束路由页
const transaction_managesuccess = '/transaction/managesuccess.do'; //办理结束保存

//师资库管理
const teacherinfo_list = '/teacherinfo/list.do'; //列表、查询
const teacherinfo_input = '/teacherinfo/input.do'; //修改路由页
const teacherinfo_save = '/teacherinfo/save.do'; //新增、修改
const teacherinfo_delete = '/teacherinfo/delete.do'; //删除
const teacherinfo_export = '/teacherinfo/export.do'; //导出路由页
const teacherinfo_exportexcel = '/teacherinfo/exportexcel.do'; //导出接口
const teacherinfo_exportsave = '/teacherinfo/exportsave.do'; //导出保存
const teacherinfodetail_list = '/teacherinfodetail/list.do'; //查看详情
const teacherinfodetail_input = '/teacherinfodetail/input.do'; //上传凭证路由页
const teacherinfodetail_input2 = '/teacherinfodetail/input2.do'; //详情修改路由
const teacherinfodetail_save = '/teacherinfodetail/save.do'; //上传凭证保存
const teacherinfodetail_delete = '/teacherinfodetail/delete.do'; //详情删除

// 上课考勤
const attendance_list = '/attendance/list.do'; //列表、查询
const attendance_input = '/attendance/input.do'; //修改路由页
const attendance_save = '/attendance/save.do'; //新增、修改
const attendance_delete = '/attendance/delete.do'; //删除

// 毕业设计
const graduationdesign_list = '/graduationdesign/list.do'; //列表、查询
const graduationdesign_input = '/graduationdesign/input.do'; //修改路由页
const graduationdesign_save = '/graduationdesign/save.do'; //新增、修改
const graduationdesign_delete = '/graduationdesign/delete.do'; //删除

// 毕业设计
const academic_list = '/academic/list.do'; //列表、查询
const academic_input = '/academic/input.do'; //修改路由页
const academic_save = '/academic/save.do'; //新增、修改
const academic_delete = '/academic/delete.do'; //删除

// 免考
const exempt_list = '/exempt/list.do'; //列表、查询
const exempt_input = '/exempt/input.do'; //修改路由页
const exempt_save = '/exempt/save.do'; //新增、修改
const exempt_delete = '/exempt/delete.do'; //删除
const courseplan_dialogresult = '/courseplan/dialogresult.do'; //删除

// 学位毕业证书管理
const academicgraduation_list = '/academicgraduation/list.do'; //列表、查询
const academicgraduation_input = '/academicgraduation/input.do'; //修改路由页
const academicgraduation_save = '/academicgraduation/save.do'; //新增、修改
const academicgraduation_delete = '/academicgraduation/delete.do'; //删除

// 符合学位管理
const conformacademic_list = '/conformacademic/list.do'; //列表、查询
const conformacademic_input = '/conformacademic/input.do'; //修改路由页
const conformacademic_save = '/conformacademic/save.do'; //新增、修改
const conformacademic_delete = '/conformacademic/delete.do'; //删除
const conformacademic_importsave = '/conformacademic/importsave.do'; //导入接口
const conformacademic_exportscore = '/conformacademic/exportscore.do'; //导出接口
const studentregister_dialogresult5 = '/studentregister/dialogresult5.do'; //学籍列表
const studentregister_getstudentregister = '/studentregister/getstudentregister.do'; //获取学生学籍

// 学位毕业证书管理
const educationexamdetail_list2 = '/educationexamdetail/list2.do'; //列表、查询
const educationexamdetail_exportscore2 = '/educationexamdetail/exportscore2.do'; //导出接口

// 学历成绩管理
const educationexam_show = '/educationexam/show.do';//查看
const educationexam_register = '/educationexam/register.do'; //录取保存
const educationexam_list = '/educationexam/list.do'; //列表、查询
const educationexam_input = '/educationexam/input.do'; //修改路由页
const educationexam_save = '/educationexam/save.do'; //新增、修改接口
const educationexam_delete = '/educationexam/delete.do'; //删除接口
const educationexam_importsave = '/educationexam/importsave.do'; //导入学生名单
const educationexam_geteducationexam = '/educationexam/geteducationexam.do'; //获取学生名单
const educationexamdetail_list = '/educationexamdetail/list.do'; //列表、查询
const educationexamdetail_input = '/educationexamdetail/input.do'; //修改路由页
const educationexamdetail_save = '/educationexamdetail/save.do'; //新增、修改
const educationexamdetail_delete = '/educationexamdetail/delete.do'; //删除
const educationexamdetail_importsave = '/educationexamdetail/importsave.do'; //导入成绩
const educationexamdetail_exportscore = '/educationexamdetail/exportscore.do'; //导出成绩
const studentregister_updateremark = '/studentregister/updateremark.do'; //备注
// 在籍学生
const studentregister_list = '/studentregister/list.do'; //列表、查询
const student_dialogresult = '/student/dialogresult.do'; //选择德立学生列表
const student_getstudent = '/student/getstudent.do'; //选定德立学生接口
const collegemajor_dialogresult1 = '/collegemajor/dialogresult1.do'; //选择院校专业列表
const collegemajor_getcollegemajor = '/collegemajor/getcollegemajor.do'; //选定院校专业
const studentregister_input = '/studentregister/input.do'; //修复路由页
const studentregister_save2 = '/studentregister/save2.do'; //新增、修改
const studentregister_delete = '/studentregister/delete.do'; //删除
const studentregister_export = '/studentregister/export.do'; //导出路由页
const studentregister_exportexcel = '/studentregister/exportexcel.do'; //导出接口
const studentregister_exportsave = '/studentregister/exportsave.do'; //导出保存
const studentregister_importsave2 = '/studentregister/importsave2.do'; //导入学籍
const studentregister_importcodesave = '/studentregister/importcodesave.do'; //导入学号、考生号
const studentregister_inputedit = '/studentregister/inputedit.do'; //查看详情
const transaction_input2 = '/transaction/input2.do'; //申请异动路由页
const transaction_list2 = '/transaction/list2.do'; //查看异动
const studentregister_importxuejistatussave = '/studentregister/importxuejistatussave.do'; //导入毕业状态
const studentregister_importorgsave = '/studentregister/importorgsave.do'; //导入合作机构学籍
const studentregister_download2 = '/studentregister/download2.do'; //学籍模板下载
const studentregister_financeapproval = '/studentregister/financeapproval.do'; //财务审核接口
const studentregister_educationalapproval = '/studentregister/educationalapproval.do'; //教务审核接口
const student_delete2 = '/student/delete2.do'; //删除
const studentregister_export1 = '/studentregister/export1.do';//学籍管理-导出
const studentregister_export2 = '/studentregister/export2.do';//毕业生管理-导出
// 职员异动
const usermove_list = '/usermove/list.do'; //列表、查询
const usermove_delete = '/usermove/delete.do'; //删除
const usermove_show = '/usermove/show.do'; //查看
const usermove_approvecheck = '/usermove/approvecheck.do'; //审批
const usermove_approvesave = '/usermove/approvesave.do'; //保存审批

//岗位描述
const position_list = '/position/list.do'  //列表
const position_save = '/position/save.do'  //增加,修改
const position_input = '/position/input.do'  //增加,修改
const position_delete = '/position/delete.do'  //删除

// 投诉建议管理
const complaint_list = '/complaint/list.do'; //列表、查询
const complaint_input = '/complaint/input.do'; //修改路由页
const complaint_save = '/complaint/save.do'; //新增修改
const complaint_delete = '/complaint/delete.do'; //删除
const complaint_show = '/complaint/show.do'; //查看
const user_dialoguserresult5 = '/user/dialoguserresult5.do'; //选择投诉人列表
const user_getuser = '/user/getuser.do'; //获取选择的用户
const complaint_handle = '/complaint/handle.do'; //处理结果

// 德立文化
const corporateculture_list = '/corporateculture/list.do'; //列表、查询
const corporateculture_input = '/corporateculture/input.do'; //修改路由页
const corporateculture_save = '/corporateculture/save.do'; //新增修改
const corporateculture_delete = '/corporateculture/delete.do'; //删除

// 年会策划
const annualmeeting_list = '/annualmeeting/list.do'; //列表、查询
const annualmeeting_input = '/annualmeeting/input.do'; //修改路由页
const annualmeeting_save = '/annualmeeting/save.do'; //新增修改
const annualmeeting_delete = '/annualmeeting/delete.do'; //删除

// 收入类型
const moneyintype_list = '/moneyintype/list.do'; //列表、查询
const moneyintype_input = '/moneyintype/input.do'; //修改路由页
const moneyintype_save = '/moneyintype/save.do'; //新增修改
const moneyintype_delete = '/moneyintype/delete.do'; //删除
const moneyintype_isenabled = '/moneyintype/isenabled.do'; //启用、禁用


// 收入二级项目
const moneyintype2_list = '/moneyintype2/list.do'; //列表、查询
const moneyintype2_input = '/moneyintype2/input.do'; //修改路由页
const moneyintype1_dialogresult = '/moneyintype1/dialogresult.do'; //收入一级项目列表
const moneyintype1_getmoneyintype1 = '/moneyintype1/getmoneyintype1.do'; //获取一级项目
const moneyintype2_save = '/moneyintype2/save.do'; //新增修改
const moneyintype2_delete = '/moneyintype2/delete.do'; //删除
const moneyintype2_export = '/moneyintype2/export.do'; //导出路由页
const moneyintype2_exportexcel = '/moneyintype2/exportexcel.do'; //导出接口
const moneyintype2_exportsave = '/moneyintype2/exportsave.do'; //导出保存
const moneyintype2_isenabled = '/moneyintype2/isenabled.do'; //启用、禁用

// 收入明细项目
const moneyinitem_list = '/moneyinitem/list.do'; //列表、查询
const moneyinitem_input = '/moneyinitem/input.do'; //修改路由页
const moneyinitem_save = '/moneyinitem/save.do'; //新增修改
const moneyinitem_delete = '/moneyinitem/delete.do'; //删除
const moneyinitem_export = '/moneyinitem/export.do'; //导出路由页
const moneyinitem_exportexcel = '/moneyinitem/exportexcel.do'; //导出接口
const moneyinitem_exportsave = '/moneyinitem/exportsave.do'; //导出保存
const moneyinitem_isenabled = '/moneyinitem/isenabled.do'; //启用、禁用

// 收据管理
const shouju_list = '/shouju/list.do'; //列表、查询
const shouju_input = '/shouju/input.do'; //修改路由页
const shouju_save = '/shouju/save.do'; //新增修改
const shouju_delete = '/shouju/delete.do'; //删除
const shouju_export = '/shouju/export.do'; //导出路由页
const shouju_exportexcel = '/shouju/exportexcel.do'; //导出接口
const shouju_exportsave = '/shouju/exportsave.do'; //导出保存

// 收入日记账
const journalaccount_list2 = '/journalaccount/list2.do'; //列表、查询
const journalaccount_show = '/journalaccount/show.do'; //查看
const journalaccount_export = '/journalaccount/export.do'; //导出路由页
const journalaccount_exportexcel = '/journalaccount/exportexcel.do'; //导出接口
const journalaccount_exportsave = '/journalaccount/exportsave.do'; //导出保存

// 账户流水账
const journalaccount_list = '/journalaccount/list.do'; //列表、查询

// 日记总账
const registerjournal_list = '/registerjournal/list.do'; //列表、查询
const registerjournal_input = '/registerjournal/input.do'; //新增、修改路由页
const registerjournal_save = '/registerjournal/save.do'; //新增、修改
const registerjournal_delete = '/registerjournal/delete.do'; //删除
const registerjournal_checksettle = '/registerjournal/checksettle.do'; //结账校验
const registerjournal_settle = '/registerjournal/settle.do'; //结账
const registerjournal_checkreturnsettle = '/registerjournal/checkreturnsettle.do'; //反结账校验
const registerjournal_returnsettle = '/registerjournal/returnsettle.do'; //反结账
const registerjournal_exportreport = '/registerjournal/exportreport.do'; //导出报表
const registerjournaldetail_list = '/registerjournaldetail/list.do'; //登账明细
const registerjournaldetail_checkoperatedetail = '/registerjournaldetail/checkoperatedetail.do'; //新增校验
const journalaccount_dialogresult = '/journalaccount/dialogresult.do'; //流水账列表
const registerjournaldetail_savedetail = '/registerjournaldetail/savedetail.do'; //新增
const registerjournaldetail_delete = '/registerjournaldetail/delete.do'; //删除
const registerjournaldetail_export = '/registerjournaldetail/export.do'; //导出路由页
const registerjournaldetail_exportexcel = '/registerjournaldetail/exportexcel.do'; //导出
const registerjournaldetail_exportsave = '/registerjournaldetail/exportsave.do'; //导出保存
const registerjournaldetail_checkmoneyoutcode = '/registerjournaldetail/checkmoneyoutcode.do'; //标注支出编号校验
const registerjournaldetail_savemoneyoutcode = '/registerjournaldetail/savemoneyoutcode.do'; //保存支出编号


// 字典类型管理
const dictype_list = '/dictype/list.do'; //列表、查询
const dictype_input = '/dictype/input.do'; //修改路由页
const dictype_save = '/dictype/save.do'; //新增修改
const dictype_delete = '/dictype/delete.do'; //删除
const dictype_enable = '/dictype/enable.do'; //启用、禁用接口
const dictype_export = '/dictype/export.do'; //导出路由页
const dictype_exportexcel = '/dictype/exportexcel.do'; //导出接口
const dictype_exportsave = '/dictype/exportsave.do'; //导出保存

// 字典键值管理
const dic_list = '/dic/list.do'; //列表、查询
const dic_input = '/dic/input.do'; //修改路由页
const dic_save = '/dic/save.do'; //新增修改
const dic_delete = '/dic/delete.do'; //删除
const dic_enable = '/dic/enable.do'; //启用、禁用接口
const dic_export = '/dic/export.do'; //导出路由页
const dic_exportexcel = '/dic/exportexcel.do'; //导出接口
const dic_exportsave = '/dic/exportsave.do'; //导出保存
const dictype_combox = '/dictype/combox.do'; //字典类型编号列表
const dic_datasave = '/dic/datasave.do'; //保存接口

// 角色管理
const role_list = '/role/list.do'; //列表、查询
const role_input = '/role/input.do'; //修改路由页
const role_save = '/role/save.do'; //新增修改
const role_delete = '/role/delete.do'; //删除
const role_enable = '/role/enable.do'; //启用、禁用接口
const rolemodule_list = '/rolemodule/list.do'; //分配权限路由页
const rolemodule_save = '/rolemodule/save.do'; //分配权限保存
const role_export = '/role/export.do'; //导出路由页
const role_exportexcel = '/role/exportexcel.do'; //导出接口
const role_exportsave = '/role/exportsave.do'; //导出保存

// 登录失败日志
const loginfaillog_list = '/loginfaillog/list.do'; //列表、查询
const loginfaillog_delete = '/loginfaillog/delete.do'; //删除
const loginfaillog_export = '/loginfaillog/export.do'; //导出路由页
const loginfaillog_exportexcel = '/loginfaillog/exportexcel.do'; //导出接口
const loginfaillog_exportsave = '/loginfaillog/exportsave.do'; //导出保存

// 操作日志
const oplog_list = '/oplog/list.do'; //列表、查询
const oplog_export = '/oplog/export.do'; //导出路由页
const oplog_exportexcel = '/oplog/exportexcel.do'; //导出接口
const oplog_exportsave = '/oplog/exportsave.do'; //导出保存

// 友情链接
const links_list = '/links/list.do'; //列表、查询
const links_input = '/links/input.do'; //修改路由页
const links_save = '/links/save.do'; //新增修改
const links_delete = '/links/delete.do'; //删除

//分组管理
const usergroup_list = '/usergroup/list.do'; //列表
const usergroup_save = '/usergroup/save.do'; //保存
const usergroup_input = '/usergroup/input.do'; //保存
const usergroup_delete = '/usergroup/delete.do'; //删除
const usergroup_show = '/usergroup/show.do'; //详情列表
const user_dialoguerrsult6 = '/user/dialoguserresult6.do'; //添加成员列表
const usergroup_save2 = '/usergroup/save2.do'; //确定
// 文件通知
const bulletin_list = '/bulletin/list.do'; //列表、查询
const bulletin_input = '/bulletin/input.do'; //修改路由页
const bulletin_save = '/bulletin/save.do'; //新增修改
const bulletin_delete = '/bulletin/delete.do'; //删除
const usergroup_group = '/usergroup/group.do'; //群列表
const usergroup_list2 = '/usergroup/list2.do'; //加载发送组
const usergroup_getuser = '/usergroup/getuser.do'; //根据选择的发送组加载发送范围（即成员）
const bulletin_approvecheck = '/bulletin/approvecheck.do'; //审批检查
const bulletin_approvesave = '/bulletin/approvesave.do'; //审批保存
//查看通知
const bulletionbject_list = '/bulletinobject/list.do'; //列表
const bulletinobject_show = '/bulletinobject/show.do'; //详情
// 文件共享
const fileshare_list = '/fileshare/list.do'; //列表、查询
const fileshare_input = '/fileshare/input.do'; //修改路由页
const fileshare_save = '/fileshare/save.do'; //新增修改
const fileshare_delete = '/fileshare/delete.do'; //删除

// 合同管理
const contract_list = '/contract/list.do'; //列表、查询
const contract_input = '/contract/input.do'; //修改路由页
const contract_save = '/contract/save.do'; //新增修改
const contract_delete = '/contract/delete.do'; //删除



// 印章分类
const seal_list = '/seal/list.do'; //列表、查询
const seal_input = '/seal/input.do'; //修改路由页
const seal_save = '/seal/save.do'; //新增修改
const seal_delete = '/seal/delete.do'; //删除

// 印章使用申请
const sealrecord_list = '/sealrecord/list.do'; //列表、查询
const sealrecord_save = '/sealrecord/save.do'; //新增修改
const sealrecord_requestapprove = '/sealrecord/requestapprove.do'; //提请审批
const sealrecord_approvesave = '/sealrecord/approvesave.do'; //保管人审批保存
const sealrecord_returns = '/sealrecord/returns.do'; //归还
const sealrecord_delete = '/sealrecord/delete.do'; //删除

//账户期末金额
const prebalances_list = '/prebalances/list.do'; //列表、查询

// 学历个人跟进奖励标准
const followup_list = '/followup/list.do'; //列表、查询
const followup_input = '/followup/input.do'; //修改路由页
const followup_save = '/followup/save.do'; //新增修改
const followup_delete = '/followup/delete.do'; //删除
const followup_importsave = '/followup/importsave.do'; //导入

// 本部转账管理
const banktobank_list = '/banktobank/list.do'; //列表、查询
const banktobank_save = '/banktobank/save.do'; //新增修改
const banktobank_export = '/banktobank/export.do'; //导出路由页
const banktobank_exportexcel = '/banktobank/exportexcel.do'; //导出接口
const banktobank_exportsave = '/banktobank/exportsave.do'; //导出保存

// 费用报销
const moneypay_anyList = '/moneypay/list2.do'; //费用报销列表

// 支出登记
const moneypay_checkpay = '/moneypay/checkpay.do';//支出校验
const moneypay_save = '/moneypay/save.do';//修改保存
const moneypay_list = '/moneypay/list.do'; //列表、查询
const moneypay_input = '/moneypay/input.do'; //修改路由页
const moneypay_addall = '/moneypay/addall.do'; //快速登记 - 保存
const moneypay_delete = '/moneypay/delete.do'; //删除
const moneypaydetail_save = '/moneypaydetail/save.do'; //增加明细
const moneypaydetail_input = '/moneypaydetail/input.do'; //增加明细 - 路由
const moneypaydetail_list = '/moneypaydetail/list.do'; //查看明细
const moneypay_requestapprove = '/moneypay/requestapprove.do'; //提请审批
const moneypay_approvecheck = '/moneypay/approvecheck.do'; //审批检查
const moneypay_approvesave = '/moneypay/approvesave.do'; //审批保存
const moneypay_print = '/moneypay/print.do'; //打印报销单
const moneypay_pay = '/moneypay/pay.do'; //支出
const moneypay_checkbalance = '/moneypay/checkbalance.do'; //冲账校验
const moneypay_inputbalance = '/moneypay/inputbalance.do'; //冲账路由页
const moneypay_savebalance = '/moneypay/savebalance.do'; //冲账
const moneypay_checkcopy = '/moneypay/checkcopy.do'; //复制校验
const moneypay_copymoneypay = '/moneypay/copymoneypay.do'; //复制
const moneypay_export = '/moneypay/export.do'; //导出路由页
const moneypay_exportexcel = '/moneypay/exportexcel.do'; //导出接口
const moneypay_exportsave = '/moneypay/exportsave.do'; //导出保存
const moneypay_importsave = 'moneypay/importsave.do'; //导入接口

// 课程安排
const courseplan_list = '/courseplan/list.do'; // 列表, 查询
const courseplan_delete = '/courseplan/delete.do'; // 删除
const courseplan_input = '/courseplan/input.do'; // 路由页
const courseplan_save = '/courseplan/save.do'; // 新增, 修改保存
const courseplan_importsave = '/courseplan/importsave.do'; // 导入
const courseplan_export2 = '/courseplan/export2.do'; // 导入



// 课程安排
const examplan_list = '/examplan/list.do'; // 列表, 查询
const examplan_delete = '/examplan/delete.do'; // 删除
const examplan_input = '/examplan/input.do'; // 路由页
const examplan_save = '/examplan/save.do'; // 新增, 修改保存
const examplan_importsave = '/examplan/importsave.do'; // 导入
const examplan_export2 = '/examplan/export2.do'; // 导出


// 院校收费标准
const collegefeestandard_list = '/collegefeestandard/list.do'; // 路由页
const collegefeestandard_input = '/collegefeestandard/input.do'; // 路由页
const collegefeestandard_save = '/collegefeestandard/save.do'; // 保存
const collegefeestandard_delete = '/collegefeestandard/delete.do'; // 删除
const collegefeestandard_approvecheck2 = '/collegefeestandard/approvecheck2.do'; // 审批检测
const collegefeestandard_approvesave2 = '/collegefeestandard/approvesave2.do'; // 审批
const collegefeestandard_importsave = '/collegefeestandard/importsave.do'; // 保存导入
const collegefeestandard_downloadtemplate = '/collegefeestandard/downloadtemplate'; // 保存导入

//课程管理
const coursemanagement_list = '/coursemanagement/list.do'; // 列表、查询
const coursemanagement_input = '/coursemanagement/input.do'; // 修改路由页
const coursemanagement_save = '/coursemanagement/save.do'; // 新增、修改
const coursemanagement_delete = '/coursemanagement/delete.do'; // 删除
const coursemanagement_importsave = '/coursemanagement/importsave.do'; // 导入

//学生考试管理
const studentexamplan_list = '/studentexamplan/list.do'; // 列表、查询
const studentexamplan_export = '/studentexamplan/export.do'; // 导出


// 已毕业管理
const studentregister_list2 = '/studentregister/list2.do';  // 列表、查询

// 支出类型
const moneyouttype_list = '/moneyouttype/list.do';  // 列表、查询
const moneyouttype_save = '/moneyouttype/save.do';  // 保存
const moneyouttype_input = '/moneyouttype/input.do';  // 修改路由页
const moneyouttype_delete = '/moneyouttype/delete.do';  // 删除
const moneyouttype_isenabled = '/moneyouttype/isenabled.do';  // 启用, 禁用


// 支出二级项目
const moneyouttype2_list = '/moneyouttype2/list.do';  // 列表、查询
const moneyouttype2_save = '/moneyouttype2/save.do';  // 保存
const moneyouttype2_input = '/moneyouttype2/input.do';  // 修改路由页
const moneyouttype2_delete = '/moneyouttype2/delete.do';  // 删除
const moneyouttype2_isenabled = '/moneyouttype2/isenabled.do';  // 启用, 禁用
const moneyouttype2_export = '/moneyouttype2/export.do';  // 导出路由页
const moneyouttype2_exportexcel = '/moneyouttype2/exportexcel.do';  // 导出下载
const moneyouttype2_exportsave = '/moneyouttype2/exportsave.do';  // 导出保存
const moneyouttype1_list = '/moneyouttype1/list.do';  // 一级项目类型





// 支出一级项目
const moneyouttype1_input = '/moneyouttype1/input.do'; // 修改路由页
const moneyouttype1_save = '/moneyouttype1/save.do'; // 新增、修改
const moneyouttype1_delete = '/moneyouttype1/delete.do'; // 删除
const moneyouttype1_export = '/moneyouttype1/export.do'; // 导出路由页
const moneyouttype1_exportexcel = '/moneyouttype1/exportexcel.do'; // 导出接口
const moneyouttype1_exportsave = '/moneyouttype1/exportsave.do'; // 导出保存
const moneyouttype1_isenabled = '/moneyouttype1/isenabled.do'; // 启用、禁用




// 支出明细项目
const moneyoutitem_list = '/moneyoutitem/list.do'; //列表、查询
const moneyoutitem_input = '/moneyoutitem/input.do'; //修改路由页
const moneyoutitem_save = '/moneyoutitem/save.do'; //新增修改
const moneyoutitem_delete = '/moneyoutitem/delete.do'; //删除
const moneyoutitem_export = '/moneyoutitem/export.do'; //导出路由页
const moneyoutitem_exportexcel = '/moneyoutitem/exportexcel.do'; //导出接口
const moneyoutitem_exportsave = '/moneyoutitem/exportsave.do'; //导出保存
const moneyoutitem_isenabled = '/moneyoutitem/isenabled.do'; //启用、禁用


// 支出日记账
const journalaccount_list3 = '/journalaccount/list3.do'; //列表、查询


// 基本工资
const wages_list = '/wages/list.do'; //列表、查询
const wages_input = '/wages/input.do'; //修改路由页
const wages_save = '/wages/save.do'; //新增修改
const wages_delete = '/wages/delete.do'; //删除
const wages_importsave = '/wages/importsave.do'; //导出路由页

// 特殊福利
const special_list = '/special/list.do'; //列表、查询
const special_input = '/special/input.do'; //修改路由页
const special_save = '/special/save.do'; //新增修改
const special_delete = '/special/delete.do'; //删除

// 基本福利
const welfare_list = '/welfare/list.do'; //列表、查询
const welfare_input = '/welfare/input.do'; //修改路由页
const welfare_save = '/welfare/save.do'; //新增修改
const welfare_delete = '/welfare/delete.do'; //删除

// 员工薪资
const salary_list = '/salary/list.do'; //列表、查询
const salary_input = '/salary/input.do'; //修改路由页
const salary_save = '/salary/save.do'; //新增修改
const salary_delete = '/salary/delete.do'; //删除
const salary_importsave = '/salary/importsave.do'; //导入
const salary_export2 = '/salary/export2.do'; //导出
const adjustment_save = '/adjustment/save.do'; // 薪资调整 - 保存
const adjustment_list = '/adjustment/list.do'; // 薪资调整 - 列表
const adjustment_delete = '/adjustment/delete.do'; // 薪资调整 - 删除

// 取现管理
const banktocash_list = '/banktocash/list.do'; //列表、查询
const banktocash_input = '/banktocash/input.do'; //新增路由页
const banktocash_save = '/banktocash/save.do'; //新增修改
const banktocash_export = '/banktocash/export.do'; //导出路由页
const banktocash_exportexcel = '/banktocash/exportexcel.do'; //导出下载
const banktocash_exportsave = '/banktocash/exportsave.do'; //导出保存

// 存款管理
const cashtobank_list = '/cashtobank/list.do'; //列表、查询
const cashtobank_checkinput = '/cashtobank/checkinput.do'; //新增校验
const cashtobank_input = '/cashtobank/input.do'; //新增路由页
const cashtobank_save = '/cashtobank/save.do'; //新增修改
const cashtobank_export = '/cashtobank/export.do'; //导出路由页
const cashtobank_exportexcel = '/cashtobank/exportexcel.do'; //导出下载
const cashtobank_exportsave = '/cashtobank/exportsave.do'; //导出保存


// 存款管理
const moneyiecv_list = '/moneyiecv/list.do'; //列表、查询
const moneyiecv_checkmoneyiecv = '/moneyiecv/checkmoneyiecv.do'; //新增明细、修改、删除校验
const moneyiecv_input = '/moneyiecv/input.do'; //修改路由页
const moneyiecv_save = '/moneyiecv/save.do'; //新增修改
const moneyiecv_delete = '/moneyiecv/delete.do'; //删除
const moneyiecvdetail_save = '/moneyiecvdetail/save.do'; //增加明细
const moneyiecvdetail_list = '/moneyiecvdetail/list.do'; //查看明细
const moneyiecvdetail_input = '/moneyiecvdetail/input.do'; //查看明细路由
const moneyiecvdetail_delete = '/moneyiecvdetail/delete.do'; //查看明细--删除
const moneyiecv_charges = '/moneyiecv/charges.do'; //确认收费
const moneyiecv_checkbalance = '/moneyiecv/checkbalance.do'; //冲账校验
const moneyiecv_inputbalance = '/moneyiecv/inputbalance.do'; //冲账路由页
const moneyiecv_savebalance = '/moneyiecv/savebalance.do'; //冲账
const moneyiecv_export = '/moneyiecv/export.do'; //导出路由页
const moneyiecv_exportexcel = '/moneyiecv/exportexcel.do'; //导出
const moneyiecv_exportsave = '/moneyiecv/exportsave.do'; //导出保存
const moneyiecv_saveall = '/moneyiecv/saveall.do'; //快速登记
const moneyiecv_saveAndprint = '/moneyiecv/saveAndprint.do';//确认保存纸质版本
const moneyiecv_checkprint = '/moneyiecv/checkprint.do'; //是否可打印
const moneyiecv_print = '/moneyiecv/print.do'; //打印收据
const moneyiecv_printshoujuPaper = '/moneyiecv/printshoujuPaper.do'; //打印收据
const moneyiecv_chargesandprint = '/moneyiecv/chargesandprint.do';//确认并打印
const moneyiecv_chargesandprintcheck = ' /moneyiecv/chargesandprintcheck.do';//确认并打印校验
// 技能收费标准
const stufudaofeestandard_list = '/stufudaofeestandard/list.do'; //列表、查询
const stufudaofeestandard_input = '/stufudaofeestandard/input.do'; //修改路由页
const stufudaofeestandard_save = '/stufudaofeestandard/save.do'; //新增修改
const stufudaofeestandard_save2 = '/stufudaofeestandard/save2.do'; //
const stufudaofeestandard_delete = '/stufudaofeestandard/delete.do'; //删除
const techfeestandard_approvecheck = '/techfeestandard/approvecheck.do'; //检查审批
const techfeestandard_approvesave = '/techfeestandard/approvesave.do'; //审批
const stufudaofeestandard_requestapprove = '/stufudaofeestandard/requestapprove.do'; //提请审批
const stufodaofeestandarddetails_list = '/stufudaofeestandarddetail/list.do'; //列表查看
const stufodaofeestandarddwtaills_delete = '/stufudaofeestandarddetail/delete.do'; //删除
const stufudaofeestandarddetail_input = '/stufudaofeestandarddetail/input.do'; //修改路由
const stufudaofeestandarddetil_save2 = '/stufudaofeestandarddetail/save2.do'; //保存
const stufudaofeestandarddetail_gettpe = '/stufudaofeestandarddetail/gettype.do'; //
// 个人计划
const userplan_list = '/userplan/list.do'; //列表、查询
const userplan_input = '/userplan/input.do'; //修改路由页
const userplan_save = '/userplan/save.do'; //新增修改
const userplan_delete = '/userplan/delete.do'; //删除
const userplan_importsave = '/userplan/importsave.do'; //导入


// 互动美语招生计划
const schoolplan_list = '/schoolplan/list.do'; //列表、查询
const schoolplan_input = '/schoolplan/input.do'; //修改路由页
const schoolplan_save = '/schoolplan/save.do'; //新增修改
const schoolplan_delete = '/schoolplan/delete.do'; //删除
const schoolplan_importsave = '/schoolplan/importsave.do'; //导入

// 互动美语招生计划
const englishplan_list = '/englishplan/list.do'; //列表、查询
const englishplan_input = '/englishplan/input.do'; //修改路由页
const englishplan_save = '/englishplan/save.do'; //新增修改
const englishplan_delete = '/englishplan/delete.do'; //删除
const englishplan_importsave = '/englishplan/importsave.do'; //导入

// 年度院校招生计划
const enrollplan_list = '/enrollplan/list.do'; //列表、查询
const enrollplan_input = '/enrollplan/input.do'; //修改路由页
const enrollplan_save = '/enrollplan/save.do'; //新增修改
const enrollplan_delete = '/enrollplan/delete.do'; //删除
const enrollplandetail_save = '/enrollplandetail/save.do'; //增加明细保存
const enrollplandetail_list = '/enrollplandetail/list.do'; //查看明细
const enrollplan_requestapprove = '/enrollplan/requestapprove.do'; //提请审批
const enrollplan_approvecheck = '/enrollplan/approvecheck.do'; //审批检查
const enrollplan_approvesave = '/enrollplan/approvesave.do'; //审批保存
const collegemajorenroll_getcollegemajorenroll2 = '/collegemajorenroll/getcollegemajorenroll2.do'; //获取专业申报
const enrollplandetail_savebatch = '/enrollplandetail/savebatch.do'; //批量保存
const enrollplan_export2 = '/enrollplan/export2.do'; //导出
const enrollplandetail_delete = '/enrollplandetail/delete.do'; //详情里面的 - 删除
const stufudaofeestandard_approvecheck = '/stufudaofeestandard/approvecheck.do'; //检查审批
const stufudaofeestandard_approvesave = '/stufudaofeestandard/approvesave.do'; //审批



// 购买辅导教材
const studentbook_list = '/studentbook/list.do'; // 列表 查询
const studentbook_input = '/studentbook/input.do'; // 修改路由
const studentbook_canbuy = '/studentbook/canbuy.do'; // 列表 查询
const studentbook_buy = '/studentbook/buy.do'; // 收款路由
const studentbookdetail_list = 'studentbookdetail/list.do'; // 查看明细
const studentbook_savebuy = '/studentbook/savebuy.do'; // 收款
const studentbook_canfeeback = '/studentbook/canfeeback.do'; // 判断是否已申请退费
const studentbook_feeback = '/studentbook/feeback.do'; // 退费路由
const studentbook_savestubookfeeback = '/studentfeeback/savestubookfeeback.do'; // 列表 查询
const studentbook_delete = '/studentbook/delete.do'; // 删除



// 入学注册
const student_exportstudent = '/student/exportstudent.do';//学历继续教育导出
const studentregisterfeedetail_list = '/studentregisterfeedetail/list.do'; // 列表, 查询
const studentregisterfeedetail_registerfee = '/studentregisterfeedetail/registerfee.do'; // 入学注册缴费路由
const studentregisterfeedetail_saveregisterfee = 'studentregisterfeedetail/saveregisterfee.do'; // 入学注册(收费)
const studentregisterfeedetail_canfeeback = '/studentregisterfeedetail/canfeeback.do'; // 判断是否已申请退费
const studentregisterfeedetail_feeback = '/studentregisterfeedetail/feeback.do'; // 退费路由
const studentfeeback_savesturegisterfeeback = '/studentfeeback/savesturegisterfeeback.do'; // 退费保存
const moneyintype2_getmoneyintype2byname = '/moneyintype2/getmoneyintype2byname.do'; // 二级目录名称
const studentregisterfeedetail_updatediscountfee = 'studentregisterfeedetail/updatediscountfee.do'; // 补优惠（确认)
const studentregisterfeedetail_updateextrasubmoney = '/studentregisterfeedetail/updateextrasubmoney.do'; // 列表, 查询
const studentregisterfeedetail_updateotherfee = '/studentregisterfeedetail/updateotherfee.do'; // 补其它
const studentregisterfeedetail_paytoschoolmoney = '/studentregisterfeedetail/paytoschoolmoney.do'; // 自交平台
const studentregisterfeedetail_show = '/studentregisterfeedetail/show.do'; // 修改路由页
const studentregisterfeedetail_save = '/studentregisterfeedetail/save.do'; // 增加修改
const studentregisterfeedetail_delete = '/studentregisterfeedetail/delete.do'; // 删除
const studentregisterfeedetail_exportdetail = '/studentregisterfeedetail/exportdetail.do'; // 导出
const studentregisterfeedetail_checkprint = '/studentregisterfeedetail/checkprint.do'; // 检查打印收据
const studentregisterfeedetail_print = '/studentregisterfeedetail/print.do'; // 打印收据
const studentregisterfeedetail_printshoujuPaper = '/studentregisterfeedetail/printshoujuPaper.do'; // 打印收据纸质
const studentregisterfeedetail_checkstudentname = '/studentregisterfeedetail/checkstudentname.do';//名单校验
const discount_loaddiscount = '/discount/loaddiscount.do';//加载优惠券
const dailypay_paylist = '/dailypay/paylist.do';//日常往来：付款记录
const studentregisterfeedetail_input = '/studentregisterfeedetail/input.do'; //
const discount_export = '/discount/export.do'; //导出
const studentregisterfeedetail_importpay = '/studentregisterfeedetail/importpay.do';//导入自缴


// 老师考勤
const teacherattendance_list = '/teacherattendance/list.do';  //列表
const teacherattendance_saveall = '/teacherattendance/saveall.do';  //快速新增保存
const teacherattendance_save = '/teacherattendance/save.do';  //修改保存
const teacherattendance_input = '/teacherattendance/input.do';  //修改路由页
const teacherattendance_delete = '/teacherattendance/delete.do';  //删除
const teacherattendance_pay = '/teacherattendance/pay.do';  //支付课酬
const teacherattendance_requestapprove = '/teacherattendance/requestapprove.do';  //提请审批
const teacherattendance_approvecheck = '/teacherattendance/approvecheck.do';  //检查审批
const teacherattendance_approvesave = '/teacherattendance/approvesave.do';  //检查审批
const teacherattendancedetail_list = '/teacherattendancedetail/list.do';  //查看明细
const teacherattendancedetail_save = '/teacherattendancedetail/save.do';  //查看明细--增加
const teacherattendancedetail_input = '/teacherattendancedetail/input.do';  //查看明细--修改
const teacherattendancedetail_delete = '/teacherattendancedetail/delete.do';  //查看明细--删除

// 院校介绍
const collegetoint_list = '/collegetoint/list.do'; //列表、查询
const collegetoint_input = '/collegetoint/input.do'; //修改路由页
const collegetoint_save = '/collegetoint/save.do'; //新增、修改
const collegetoint_delete = '/collegetoint/delete.do'; //删除
const collegetoint_show = '/collegetoint/show.do'; //查看
const collegetoint_export = '/collegetoint/export.do'; //导出路由页
const collegetoint_exportexcel = '/collegetoint/exportexcel.do'; //导出接口
const collegetoint_exportsave = '/collegetoint/exportsave.do'; //导出保存
const collegetomajor_importsave = '/collegetomajor/importsave.do'; //导入保存
const collegetocourse_importsave = '/collegetocourse/importsave.do'; //导入课程
const collegetocourse_list = '/collegetocourse/list.do'; //课程列表
const collegetomajor_delete = '/collegetomajor/delete.do'; //删除专业
const collegetocourse_save = '/collegetocourse/save.do'; //新增课程
const collegetocourse_delete = '/collegetocourse/delete.do'; //删除课程
const collegetomajor_save = '/collegetomajor/save.do'; //新增专业
//技能介绍
const techtoint_list = '/techtoint/list.do';//列表
const teachoint_input = 'techtoint/input.do'; //修改
const teachoint_save = 'techtoint/save.do';
const techtoint_show = 'techtoint/show.do';
const techtoint_delete = 'techtoint/delete.do';
const fudaoclasstype_dialogresult = 'fudaoclasstype/dialogresult.do';


// 校外教学点管理
const studentregister_getclassname = '/studentregister/getclassname.do';//获取上课点接口
const collegecoporg_list = '/collegecoporg/list.do';  // 列表
const collegecoporg_importsave = '/collegecoporg/importsave.do';  // 导入
const collegecoporg_delete = '/collegecoporg/delete.do';  // 删除
const collegecorg_save = '/collegecoporg/save.do';//提交
const collegecoporg_input = 'collegecoporg/input.do'; //修改

// 辅导班别管理
const fudaoclasstype_list = '/fudaoclasstype/list.do';  // 列表
const fudaoclasstype_save = '/fudaoclasstype/save.do';  // 新增
const fudaoclasstype_input = '/fudaoclasstype/input.do';  // 路由
const fudaoclasstype_delete = '/fudaoclasstype/delete.do';  // 删除

// 试卷统计
const paperprint_list = '/paperprint/list.do';  // 列表
const paperprint_input = '/paperprint/input.do';  // 修改路由页
const paperprint_save = '/paperprint/save.do';  // 新增、修改
const paperprint_delete = '/paperprint/delete.do';  // 删除
const paperprint_exporttotal2 = '/paperprint/exporttotal2.do';  // 导出
const paperprint_downloadtemplate = '/paperprint/downloadtemplate.do';  // 模板下载
const paperprint_importsave = '/paperprint/importsave.do';  // 导入

//首页
const performance_prestatistics = '/performance/prestatistics.do' //个人业绩
const performance_edupersonal = '/performance/edupersonal.do' //学历英雄榜
const performance_completion = '/performance/completion.do' //目标完成情况

//欠费催收
//代预报名费催收
const studentyubaoming_arrearslist = '/studentyubaoming/arrearslist.do'  // - 列表
const studentyubaoming_inarrearscollect = '/studentyubaoming/inarrearscollect.do'  //收款 - 路由页
const studentyubaoming_savearrearscollect = '/studentyubaoming/savearrearscollect.do'  //收款 - 保存
const studentyubaoming_exportqianfei = '/studentyubaoming/exportqianfei.do'  //导出
//德立学生学位费催收
const student_arrearslist = '/student/arrearslist.do'  // - 列表
const student_inarrearscollect = '/student/inarrearscollect.do'  //收款 - 路由页
const student_savearrearscollect = '/student/savearrearscollect.do'  //收款 - 保存
const student_exportqianfei = '/student/exportqianfei.do'  //导出
//购买辅导教材费催收
const studentbook_arrearslist = '/studentbook/arrearslist.do'  // - 列表
const studentbook_inarrearscollect = '/studentbook/inarrearscollect.do'  //收款 - 路由页
const studentbook_savearrearscollect = '/studentbook/savearrearscollect.do'  //收款 - 保存
const studentbook_exportqianfei = '/studentbook/exportqianfei.do'  //导出
//入学注册学费催收
const studentregisterfeedetail_arrearslist = '/studentregisterfeedetail/arrearslist.do'  // - 列表
const studentregisterfeedetail_incollect = '/studentregisterfeedetail/incollect.do'  //收款 - 路由页
const studentregisterfeedetail_saveincollect = '/studentregisterfeedetail/saveincollect.do'  //收款 - 保存
const studentregisterfeedetail_exportqianfei = '/studentregisterfeedetail/exportqianfei.do'  //导出
//报读辅导班学费催收
const studentfudao_arrearslist = '/studentfudao/arrearslist.do'  // - 列表
const studentfudao_inarrearscollect = '/studentfudao/inarrearscollect.do'  //收款 - 路由页
const studentfudao_savearrearscollect = '/studentfudao/savearrearscollect.do'  //收款 - 保存
const studentfudao_exportqianfei = '/studentfudao/exportqianfei.do'  //导出
//收费记录
const studentmoneyrecv_list = '/studentmoneyrecv/list.do'  //列表
const studentmoneyrecv_printshouju = '/studentmoneyrecv/printshouju.do'  //打印收据
const studentmoneyrecv_printshoujuPaper  = '/studentmoneyrecv/printshoujuPaper.do'  //打印收据--纸质收据
const studentmoneyrecv_invert = '/studentmoneyrecv/invert.do'  //冲账路由
const studentmoneyrecv_invertsave = '/studentmoneyrecv/invertsave.do'  //冲账

//未读消息
const notice_totalcount = '/notice/totalcount.do' //未读数量

//函授站学生管理
const student_guakaoschoollist = '/student/guakaoschoollist.do' //列表

//开课点学生管理
const student_classaddresslist = '/student/classaddresslist.do' //列表

export {
  student_classaddresslist,
  student_guakaoschoollist,
  notice_totalcount,
  techclass_list,
  techclass_save,
  techclass_delete,
  techclass_list2,
  fudaoclasstype_list2,
  fudaoclasstype_save2,
  fudaoclasstype_list,
  fudaoclasstype_save,
  fudaoclasstype_input,
  fudaoclasstype_delete,
  collegecoporg_list,
	studentregister_getclassname,
  collegecoporg_importsave,
  collegecoporg_delete,
	collegecorg_save,
	collegecoporg_input,
  collegetoint_list,
	techtoint_list,
	teachoint_input,
	teachoint_save,
	techtoint_show,
	techtoint_delete,
	fudaoclasstype_dialogresult,
  collegetoint_input,
  collegetoint_save,
  collegetoint_delete,
  collegetoint_show,
  collegetoint_export,
  collegetoint_exportexcel,
  collegetoint_exportsave,
  collegetomajor_importsave,
  collegetocourse_importsave,
  collegetocourse_list,
  collegetomajor_delete,
  collegetocourse_save,
  collegetocourse_delete,
  collegetomajor_save,
  studentmoneyrecv_list,
  studentmoneyrecv_printshouju,
	studentmoneyrecv_printshoujuPaper,
  studentmoneyrecv_invert,
  studentmoneyrecv_invertsave,
  studentfudao_arrearslist,
  studentfudao_inarrearscollect,
  studentfudao_savearrearscollect,
  studentfudao_exportqianfei,
  studentregisterfeedetail_arrearslist,
  studentregisterfeedetail_incollect,
  studentregisterfeedetail_saveincollect,
  studentregisterfeedetail_exportqianfei,
  studentbook_arrearslist,
  studentbook_inarrearscollect,
  studentbook_savearrearscollect,
  studentbook_exportqianfei,
  student_exportqianfei,
	student_save2,
  student_savearrearscollect,
  student_inarrearscollect,
  student_arrearslist,
  studentyubaoming_exportqianfei,
	studentyubaoming_exportsave,
	studentyubaoming_export2,
  studentyubaoming_savearrearscollect,
  studentyubaoming_inarrearscollect,
  studentyubaoming_arrearslist,
  performance_prestatistics,
  performance_edupersonal,
  performance_completion,
  position_list,
  position_save,
  position_input,
  position_delete,
  salary_list,
  salary_input,
  salary_save,
  salary_delete,
  salary_importsave,
  salary_export2,
  adjustment_save,
  adjustment_list,
  adjustment_delete,
  building_save,
  building_delete,
  building_list,
  building_input,

  dailycover_save,
  dailycover_list,
  dailycover_input,
  dailycover_delete,

  elegant_save,
  elegant_delete,
  elegant_list,
  elegant_input,
  businessvideo_list,
  businessvideo_save,
  businessvideo_input,
  businessvideo_delete,
  fivestars_save,
  fivestars_delete,
  fivestars_list,
  fivestars_input,

  paperprint_list,
  paperprint_input,
  paperprint_save,
  paperprint_delete,
  paperprint_exporttotal2,
  paperprint_downloadtemplate,
  paperprint_importsave,
  teacherattendance_list,
  teacherattendance_pay,
  teacherattendance_save,
  teacherattendance_input,
  teacherattendance_delete,
  teacherattendance_saveall,
  teacherattendancedetail_list,
  teacherattendancedetail_save,
  teacherattendance_approvesave,
  teacherattendancedetail_input,
  teacherattendance_approvecheck,
  teacherattendancedetail_delete,
  teacherattendance_requestapprove,
  studentbook_list,
  studentbook_input,
  studentbook_canbuy,
  studentbook_buy,
  studentbook_savebuy,
  studentbook_canfeeback,
  studentbook_feeback,
  studentbookdetail_list,
  studentbook_savestubookfeeback,
  studentbook_delete,
  enrollplan_list,
  enrollplan_input,
  enrollplan_save,
  enrollplan_delete,
  enrollplandetail_save,
  enrollplandetail_list,
  enrollplan_requestapprove,
  enrollplan_approvecheck,
  enrollplan_approvesave,
  generalmoneyiecv_moneyiecvlist,
  studentdeli_exportchengkao,
	generalmoneyiecv_export2,
  collegemajorenroll_getcollegemajorenroll2,
  enrollplandetail_savebatch,
  enrollplan_export2,
  enrollplandetail_delete,
  schoolplan_list,
  schoolplan_input,
  schoolplan_save,
  schoolplan_delete,
  schoolplan_importsave,
  englishplan_list,
  englishplan_input,
  englishplan_save,
  englishplan_delete,
  englishplan_importsave,
  userplan_list,
  userplan_input,
  userplan_save,
  userplan_delete,
  userplan_importsave,
  stufudaofeestandard_list,
  stufudaofeestandard_input,
	stufodaofeestandarddetails_list,
	stufodaofeestandarddwtaills_delete,
	stufudaofeestandarddetail_input,
	stufudaofeestandarddetil_save2,
	stufudaofeestandarddetail_gettpe,
  stufudaofeestandard_save,
	stufudaofeestandard_save2,
  stufudaofeestandard_approvecheck,
  stufudaofeestandard_approvesave,
  stufudaofeestandard_delete,
  techfeestandard_approvecheck,
  techfeestandard_approvesave,
  stufudaofeestandard_requestapprove,
  moneyiecv_list,
  moneyiecv_checkmoneyiecv,
  moneyiecv_input,
  moneyiecv_save,
  moneyiecv_delete,
  moneyiecvdetail_save,
  moneyiecvdetail_list,
  moneyiecvdetail_input,
  moneyiecvdetail_delete,
  moneyiecv_charges,
  moneyiecv_checkbalance,
  moneyiecv_inputbalance,
  moneyiecv_savebalance,
  moneyiecv_checkprint,
	 moneyiecv_chargesandprintcheck,
	moneyiecv_chargesandprint,
  moneyiecv_print,
	moneyiecv_printshoujuPaper,
  moneyiecv_export,
  moneyiecv_exportexcel,
  moneyiecv_exportsave,
  moneyiecv_saveall,
	moneyiecv_saveAndprint,

  cashtobank_input,
  cashtobank_save,
  cashtobank_list,
  cashtobank_checkinput,
  cashtobank_export,
  cashtobank_exportexcel,
  cashtobank_exportsave,

  banktocash_list,
  banktocash_input,
  banktocash_save,
  banktocash_export,
  banktocash_exportexcel,
  banktocash_exportsave,

  welfare_list,
  welfare_input,
  welfare_save,
  welfare_delete,

  special_list,
  special_input,
  special_save,
  special_delete,

  wages_list,
  wages_input,
  wages_save,
  wages_delete,
  wages_importsave,

  journalaccount_list3,

  moneyoutitem_list,
  moneyoutitem_input,
  moneyoutitem_save,
  moneyoutitem_delete,
  moneyoutitem_export,
  moneyoutitem_exportexcel,
  moneyoutitem_exportsave,
  moneyoutitem_isenabled,

  moneyouttype1_list,
  moneyouttype1_input,
  moneyouttype1_save,
  moneyouttype1_delete,
  moneyouttype1_export,
  moneyouttype1_exportexcel,
  moneyouttype1_exportsave,
  moneyouttype1_isenabled,

  moneyouttype2_list,
  moneyouttype2_save,
  moneyouttype2_input,
  moneyouttype2_delete,
  moneyouttype2_isenabled,
  moneyouttype2_export,
  moneyouttype2_exportexcel,
  moneyouttype2_exportsave,

  moneyouttype_list,
  moneyouttype_save,
  moneyouttype_input,
  moneyouttype_delete,
  moneyouttype_isenabled,
	moneypay_checkpay,
	moneypay_anyList,
	moneypay_save,
  moneypay_list,
  moneypay_input,
  moneypay_addall,
  moneypay_delete,
  moneypaydetail_save,
  moneypaydetail_input,
  moneypaydetail_list,
  moneypay_requestapprove,
  moneypay_approvecheck,
  moneypay_approvesave,
  moneypay_print,
  moneypay_pay,
  moneypay_checkbalance,
  moneypay_inputbalance,
  moneypay_savebalance,
  moneypay_checkcopy,
  moneypay_copymoneypay,
  moneypay_export,
  moneypay_exportexcel,
  moneypay_importsave,
  moneypay_exportsave,
  banktobank_list,
  banktobank_save,
  banktobank_export,
  banktobank_exportexcel,
  banktobank_exportsave,
  followup_list,
  followup_input,
  followup_save,
  followup_delete,
  followup_importsave,
  prebalances_list,
  sealrecord_list,
  sealrecord_save,
  sealrecord_requestapprove,
  sealrecord_approvesave,
  sealrecord_returns,
  sealrecord_delete,
  seal_list,
  seal_input,
  seal_save,
  seal_delete,
  contract_list,
  contract_input,
  contract_save,
  contract_delete,
  fileshare_list,
  fileshare_input,
  fileshare_save,
  fileshare_delete,
  links_list,
  links_input,
  links_save,
  links_delete,
  bulletin_list,
  bulletionbject_list,
  bulletinobject_show,
  usergroup_list,
  usergroup_save,
  usergroup_input,
  usergroup_delete,
  usergroup_show,
  user_dialoguerrsult6,
  usergroup_save2,
  bulletin_input,
  bulletin_save,
  bulletin_delete,
  usergroup_group,
  usergroup_list2,
  usergroup_getuser,
  bulletin_approvecheck,
  bulletin_approvesave,
  oplog_list,
  oplog_export,
  oplog_exportexcel,
  oplog_exportsave,
  loginfaillog_list,
  loginfaillog_delete,
  loginfaillog_export,
  loginfaillog_exportexcel,
  loginfaillog_exportsave,
  role_list,
  role_input,
  role_save,
  role_delete,
  role_enable,
  rolemodule_list,
  rolemodule_save,
  role_export,
  role_exportexcel,
  role_exportsave,
  dic_list,
  dic_input,
  dic_save,
  dic_delete,
  dic_enable,
  dic_export,
  dic_exportexcel,
  dic_exportsave,
  dictype_combox,
  dic_datasave,
  dictype_list,
  dictype_input,
  dictype_save,
  dictype_delete,
  dictype_enable,
  dictype_export,
  dictype_exportexcel,
  dictype_exportsave,


  studentregister_list2,

  studentexamplan_list,
  studentexamplan_export,

  coursemanagement_list,
  coursemanagement_input,
  coursemanagement_save,
  coursemanagement_delete,
  coursemanagement_importsave,

  collegefeestandard_list,
  collegefeestandard_input,
  collegefeestandard_save,
  collegefeestandard_delete,
  collegefeestandard_approvesave2,
  collegefeestandard_approvecheck2,
  collegefeestandard_downloadtemplate,
  collegefeestandard_importsave,


  courseplan_list,
  courseplan_delete,
  courseplan_input,
  courseplan_save,
  courseplan_importsave,
  courseplan_export2,


  examplan_list,
  examplan_delete,
  examplan_input,
  examplan_save,
  examplan_importsave,
  examplan_export2,

  meeting_list,
  meeting_delete,
  meeting_input,
  meeting_save,


  studentfeeback_savestufudaofeeback,
  registerjournaldetail_checkoperatedetail,
  journalaccount_dialogresult,
  registerjournaldetail_savedetail,
  registerjournaldetail_delete,
  registerjournaldetail_export,
  registerjournaldetail_exportexcel,
  registerjournaldetail_exportsave,
  registerjournaldetail_checkmoneyoutcode,
  registerjournaldetail_savemoneyoutcode,
  registerjournal_list,
  registerjournal_input,
  registerjournal_save,
  registerjournal_delete,
  registerjournaldetail_list,
  registerjournal_checksettle,
  registerjournal_settle,
  registerjournal_checkreturnsettle,
  registerjournal_returnsettle,
  registerjournal_exportreport,
  journalaccount_list,
  journalaccount_list2,
  journalaccount_show,
  journalaccount_export,
  journalaccount_exportexcel,
  journalaccount_exportsave,
  shouju_list,
  shouju_input,
  shouju_save,
  shouju_delete,
  shouju_export,
  shouju_exportexcel,
  shouju_exportsave,
  moneyinitem_list,
  moneyinitem_input,
  moneyinitem_save,
  moneyinitem_delete,
  moneyinitem_export,
  moneyinitem_exportexcel,
  moneyinitem_exportsave,
  moneyinitem_isenabled,
  moneyintype2_list,
  moneyintype2_input,
  moneyintype1_dialogresult,
  moneyintype1_getmoneyintype1,
  moneyintype2_save,
  moneyintype2_delete,
  moneyintype2_export,
  moneyintype2_exportexcel,
  moneyintype2_exportsave,
  moneyintype2_isenabled,
  moneyintype_list,
  moneyintype_input,
  moneyintype_save,
  moneyintype_delete,
  moneyintype_isenabled,
  notice_list,
	usercard_getcard,
  notice_approve,
  notice_list2,
	user_updatephotourl,
	generalmoneyiecv_list,
	generalmoneyiev_inputcharges,
	generalmoneyiecv_savecharge,
	generalmoneyiecv_checkprint,
	generalmoneyiecv_print,
	generalmoneyiecv_checkapplyrefund,
	generalmoneyiecv_show,
	registerfeetotal_list,
  studentgenzong_list2,
	generalmoneyiecv_applyrefund,
	studentmoneyrecv_exportstudentmoneyrecv,
	studentmoneyrecv_requestapprove,
	studentmoneyrecv_approvecheck,
	studentmoneyrecv_approvesave,
	position_positionlist,
	studentfeeback_savegeneralmoneyiecv,
	approval_list,
	approval_delete,
	approval_save,
	approval_input,
	discount_list,
	discount_checkapprove,
	discount_approvesave,
	discount_delete,
	discount_input,
	discount_save,
  annualmeeting_list,
  annualmeeting_input,
  annualmeeting_save,
  annualmeeting_delete,
  corporateculture_list,
  corporateculture_input,
  corporateculture_save,
  corporateculture_delete,
  complaint_list,
  complaint_input,
  complaint_save,
  complaint_delete,
  complaint_show,
  complaint_handle,
  user_dialoguserresult5,
  user_getuser,
  usermove_list,
  usermove_delete,
  usermove_show,
  usermove_approvecheck,
  usermove_approvesave,
  moneyiecv_show,
	networkregister_list,
	networkregister2_listz,
	networkregister_save,
	networkregister_delete,
	networkregister_input,
	networkregister_checkqq,
	networkregister_show,
	networkregister_discompanylist,
	networkregister_savedistocompany,
	networkregister_checkdiscompany,
	networkregister_checkdischool,
	networkregister_disschoollist,
	networkregister_savedisschool,
	networkregister_checkdisstudent,
	networkregister_disstudentlist,
	networkregister_savedisstudent,

	networkregister_checkmobile,
	networkregister_checkinput,
	companyorg_list,
	schoolorg_list,
  moneyintype1_list,
  moneyintype1_input,
  moneyintype1_save,
  moneyintype1_delete,
  moneyintype1_export,
  moneyintype1_exportexcel,
  moneyintype1_exportsave,
  moneyintype1_isenabled,
	moneyiecv_entry,

  studentregister_list,
  student_dialogresult,
  student_getstudent,
  collegemajor_dialogresult1,
  collegemajor_getcollegemajor,
  studentregister_input,
  studentregister_save2,
  studentregister_delete,
  studentregister_export,
  studentregister_exportexcel,
  studentregister_exportsave,
  studentregister_importsave2,
  studentregister_importcodesave,
  studentregister_inputedit,
  transaction_input2,
  transaction_list2,
  studentregister_importxuejistatussave,
  studentregister_importorgsave,
  studentregister_download2,
  studentregister_financeapproval,
  studentregister_educationalapproval,
	student_delete2,
  educationexam_list,
  educationexam_input,
	educationexam_show,
	educationexam_register,
  educationexam_save,
  educationexam_delete,
  educationexam_importsave,
  educationexam_geteducationexam,
  educationexamdetail_list,
  educationexamdetail_input,
  educationexamdetail_save,
  educationexamdetail_delete,
  educationexamdetail_importsave,
  educationexamdetail_exportscore,
  educationexamdetail_list2,
  educationexamdetail_exportscore2,
	studentregister_updateremark,
  conformacademic_list,
  conformacademic_input,
  conformacademic_save,
  conformacademic_delete,
  courseplan_dialogresult,
  conformacademic_importsave,
  conformacademic_exportscore,
  studentregister_dialogresult5,
  studentregister_getstudentregister,
  academicgraduation_list,
  academicgraduation_input,
  academicgraduation_save,
  academicgraduation_delete,
  exempt_list,
  exempt_input,
  exempt_save,
  exempt_delete,
  academic_list,
  academic_input,
  academic_save,
  academic_delete,
  graduationdesign_list,
  graduationdesign_input,
  graduationdesign_save,
  graduationdesign_delete,
  attendance_list,
  attendance_input,
  attendance_save,
  attendance_delete,
	techfudao_inarrearscollect,
  teacherinfo_list,
  teacherinfo_input,
  teacherinfo_save,
  teacherinfo_delete,
  teacherinfo_export,
  teacherinfo_exportexcel,
  teacherinfo_exportsave,
  teacherinfodetail_list,
  teacherinfodetail_input,
  teacherinfodetail_input2,
  teacherinfodetail_save,
  teacherinfodetail_delete,
  transaction_list,
  transaction_input,
  transaction_save,
  transaction_delete,
  transaction_approve2,
  transaction_managesuccess,
  paperstock_list,
  paperstock_input,
  paperstock_save,
  paperstock_delete,
  diploma_list,
  diploma_input,
  diploma_save,
  diploma_delete,
  studentmove_list,
  studentregister_dialogresult,
  studentregister_dialogresult4,
	studentpotential_dialogresult2,
  studentregister_getstudentregister1,
  vschoolorg_dialogresult,
  vschoolorg_getvschoolorg,
  studentmove_save,
  studentmove_input,
  studentmove_delete,
  studentmove_export,
  studentmove_exportexcel,
  studentmove_exportsave,
  studentmove_batchsaverecv,
  studentmove_recvlist,
    studentmove_export1,
    studentmove_export2,
  collegemajor_list,
  collegemajor_list2,
  collegemajor_input,
  collegemajor_save,
  collegemajor_delete,
  collegemajor_export,
  collegemajor_exportexcel,
  collegemajor_exportsave,
  college_dialogresult,
  college_getcollegeinfo,
  collegemajor_importsave,
  collegemajor_show,
  college_list,
  college_input,
  college_show,
  college_save,
  college_delete,
  college_export,
  college_exportexcel,
  college_exportsave,
  college_importsave,
	examsettings_list,
	question_dialogresult2,
	examsettings_delete,
	examsettingsdetailed_list,
	examsettingsdetailed_save,
	examsettingsdetailed_input,
	examsettingsdetailed_delete,
	businessexams_list,
	businessexams_save,
	examsettings_dialogresult,
	businessexams_input,
	businessexams_delete,
	businessexams_show,
	businessexamsdetailed_start,
	businessexamsdetailed_submit,
	businessexamsdetailed_score,
	examsettings_save,
	examsettings_input,
  technocertificate_isflag,
  technocertificate_importsave,
  technocertificate_export,
  technocertificate_exportexcel,
  technocertificate_exportsave,
  technocertificate_list,
  technocertificate_input,
  technocertificate_save,
  technocertificate_delete,
  techcertificate_list,
  techcertificate_input,
  techcertificate_save,
  techcertificate_delete,
  techexam_list,
  techexam_input,
  techexam_save,
  techexamdetail_save,
  techexamdetail_input,
  techexamdetail_delete,
	educationexam_importsave2,
  techexam_delete,
  techexam_importsave,
  techexamdetail_list,
  techexamdetail_importsave,
  techexamdetail_exportscore,
  studentstudy_list,
  studentstudy_input,
  studentstudy_save,
  studentstudy_delete,
  studentstudy_export,
  studentstudy_exportexcel,
  studentstudy_exportsave,
  studentstudy_importsave,
  techfudao_dialogresult,
  techfudao_gettechfudao,
	techfudao_export4,
	teachfudao_arrearslist,
  user_input2,
  userrenewal_list,
  userrenewal_delete,
  userrenewal_input,
  userrenewal_save,
  techteacher_list,
  techclass_dialogresult,
  techteacher_input,
  techteacher_save,
  techteacher_delete,
  techteacher_export,
  techteacher_exportexcel,
  techteacher_exportsave,
  techteacherdetail_save,
  techteacherdetail_input,
  techteacherdetail_delete,
  techteacherdetail_list,
  techteachingplan_list,
  techteachingplan_input,
  techteachingplan_save,
  techteachingplan_delete,
  holiday_list,
  holiday_save,
  holiday_input,
  holiday_delete,
  holiday_show,
  holiday_export,
  holiday_exportexcel,
  holiday_exportsave,
  user_list,
  user_input,
  user_save,
  user_delete,
  user_show,
  user_setpwdsave,
  user_active,
  user_unlock,
  module_treeuser,
  usermodule_save,
  roleuser_save2,
  roleuser_list3,
  usermove_save,
  userapply_list,
  userapply_delete,
  userapply_save,
  userapply_input,
  person_list,
  person_save,
  person_input,
  person_delete,
  userinterview_save,
  userinterview_list,
  userinterview_delete,
  userinterview_flag,
  userapply_approvesave,
  userapply_requestapprove,
  studentgenzong_input,
  studentpotential_list,
  studentpotential_export,
	studentpotential_importsave,
	studentnal_list,
  studentpotential_input,
  studentpotential_save,
  studentgenzong_save,
  studentgenzong_list,
  studentbook_saveall,
  student_addstudentinput,
	student_saveinfo,
	generalmoneyiecv_input,
	generalmonyiecv_save,
  studentbook_inputall,
  studentpotential_changefollow,
  studentpotential_savechangefollow,
  account_listbank,
  account_inputbank,
  account_savebank,
  bankaccountchange_input,
  bankaccountchange_save,
  bankaccountchange_list,
  account_listcash,
  account_inputcash,
  account_savecash,
  account_delete,
  account_requestapprove,
  account_approvecheck,
  account_approvesave,
  account_export,
  account_exportexcel,
  account_exportsave,
  goodsregister_list,
  goodsregister_checkedit,
  goodsregister_input2,
  goodsregister_save,
  goodsregister_delete,
  goodsregister_isok,
  goodsregister_cancelisok,
  goodsregister_checkprint,
  goodsregister_print,
  goodsregister_exportbyid,
  goodsregister_exports,
  stockmanage_list,
  stockmanage_input,
  stockmanage_save,
  stockmanage_delete,
  stockmanage_export,
  stockmanage_exportexcel,
  stockmanage_exportsave,
  warehousing_save,
  collect_save,
  inventory_save,
  stockmanage_importsave,
  warehousing_list,
  warehousing_export,
  warehousing_exportexcel,
  warehousing_exportsave,
  collect_list,
  collect_export,
  collect_exportexcel,
  collect_exportsave,
  inventory_list,
  inventory_export,
  inventory_exportexcel,
  inventory_exportsave,
  purchase_list,
  purchase_input,
  purchase_save2,
  purchase_delete,
  purchase_requestapprove,
  purchase_approvesave,
  approve_list,
  purchase_print,
  purchasedetail_exportdetail,
  purchasedetail_list,
  purchasedetail_input,
  purchasedetail_save,
  purchasedetail_delete,
  schedulemanage_list2,
  schedulemanage_save,
  schedulemanage_delete,
  scheduleyear_save,
  scheduleyear_list,
  schedulemanagedetail_list,
  scheduleyear_delete,
  schedulemanagedetail_save,
  schedulemanagedetail_delete,
  askleave_list,
  askleave_input,
  askleave_save,
  askleave_delete,
  askleave_requestapprove,
    askleave_approvecheck,
  askleave_approvesave,
  referral_list,
  referral_input,
  referral_save,
  referral_delete,
  referral_importsave,
  accountingreward_list,
  accountingreward_input,
  accountingreward_save,
  accountingreward_delete,
  pharmacist_list,
  pharmacist_input,
  pharmacist_save,
  pharmacist_delete,
  activity_list,
  activity_input,
  activity_save,
  activity_delete,
  guarantee_list,
  guarantee_input,
  guarantee_save,
  guarantee_delete,
  educationreward_listzhiye,
  educationreward_list,
  educationreward_listkuaiji,
  educationreward_listjiaoshi,
  team_list,
  team_input,
  team_save,
  team_delete,
  personal_list,
  personal_input,
  personal_save,
  personal_delete,
  collegesincome_list,
  collegesincome_input,
  collegesincome_save,
  collegesincome_delete,
	studentuser_list,
  videocourse_list,
  question_save,
  question_input,
  question_delete,
  questiondetailed_save,
  questiondetailed_input,
  questiondetailed_delete,
  questiondetailed_importsave,
  question_list,
  questiondetailed_list,
  videocourse_save,
  videocourse_input,
  videocourse_delete,
  videocoursedetailed_getchapter,
  videocoursedetailed_savechapter,
  videocoursedetialed_save,
  videocoursedetailed_input,
  videocoursedetailsed_delte,
  videocoursedetailed_importsave,
  videocoursedetiled_list,
	studentuser_input,
	studentuser_savestudent,
	studentuser_saveisavailable,
	studentuser_saveisintergrity,
  collegesincome_export,
  collegesincome_exportexcel,
  collegesincome_exportsave,
  collegesincome_importsave,
  student_list,
	student_list2,
	student_importsave,
	student_importsave2,
	student_exportstudet2,
  student_input,
	student_input2,
	student_input3,
	videocourse_dialogresult,
	question_dialogresult,
  student_show,
  student_save,
  student_delete,
  student_checkincollect,
  student_incollect,
  student_savecollect,
  student_checkprintshouju,
  student_printshouju,
	student_exportsave,
	student_exportexcel,
	student_export,
	student_printshoujuPaper,
  student_checkapplyrefund,
  student_applyrefund,
  studentfeeback_savestudent,
  student_changeflag,
  studentyubaoming_list,
  studentyubaoming_delete,
  studentyubaoming_input,
  studentyubaoming_save,
  studentpotential_show,
	studentregister_show2,
  studentbook_checkprint,
	studentbook_printshoujuPaper,
  studentbook_print,
  studentyubaoming_checkapplyrefund,
  studentyubaoming_applyrefund,
  studentfeeback_savestudentyubaoming,
  studentyubaoming_checkincollect,
  studentyubaoming_incollect,
  studentyubaoming_savecollect,
  studentyubaoming_checkprint,
  studentyubaoming_printshouju,
	studentyubaoming_printshoujuPaper,
	studentyubaoming_exportexcel,
	studentyubaoming_export,
  studentfudao_list,
  studentfudao_delete,
  studentfudao_input,
  studentfudao_save,
  studentfudao_show,
  studentfudao_canfeeback,
  studentfudao_feeback,
  studentfudao_canenrol,
  studentfudao_enrol,
  studentfudao_saveenrol,
  studentfudao_checkprint,
  studentfudao_print,
	studentfudao_printshoujuPaper,
  techfudao_list,
  techfudao_distribution,
	techfudao_listjn,
  techfudao_delete,
	generalmoneyiecv_delete,
  techfudao_input,
  techfudao_save,
  techfudao_show,
  techfudao_canfeeback,
  techfudao_feeback,
  techfeeback_savetechfudaofeeback,
  techfudao_canenrol,
  techfudao_enrol,
  techfudao_saveenrol,
	techfudao_savearrearscollect,
  techfudao_canextrafee,
  techfudao_checkprint,
	techfudao_export2,
	techfudao_export3,
  techfudao_print,
	techfudao_printshoujuPaper,
  business_list,
  business_input,
  business_delete,
  business_save,
  collegesreceivable_list,
	collegesreceivable_save,
	studentregister_dialogresultselect,
	collegesrceivable_checkprintshouju,
	collegesreceivable_printrsceipt,
  collegesreceivable_input,
	collegesreceivable_printshoujuPaper,
  collegesreceivable_importsave,
	collegesreceivabel_import,
	collegesreceivable_improtdo,
	collegesreceivable_importance,
  collegesreceivabledetail_list,
  collegesreceivable_exportdetail,
  collegesreceivabledetail_save2,
  collegesreceivable_income,
  collegesreceivabledetail_income,
  collegesreceivabledetail_incomesave,
	collegesreceivabel_income_save,
  collegesreceivablefee_list,
  collegespay_list,
	collegespay_paylist,
	collegesreceivable_incomelist,
	colegespay_listpay,
  collegespay_input,
  collegespaydetail_list,
  collegespay_exportdetail,
  collegespaydetail_save2,
  collegespaydetail_pay,
  collegespaydetail_paysave,
  collegespayfee_list,
	collegespay_pay,
	conllespay_paysave,
  collegespay_approvecheck,
  collegespay_approvesave,
	collegespay_paylistdo,
  dailyreceivable_list,
  dailyreceivable_saveall,
  dailyreceivable_income,
  dailyreceivable_incomesave,
  dailyreceivable_delete,
  dailyreceivabledetail_list,
	dailyreceivable_checkprintshouju,
	dailyreceivable_print,
	dailyreceivable_printshoujuPaper,
  dailyreceivabledetail_save,
  dailyreceivabledetail_input,
  dailyreceivabledetail_delete,
  dailyreceivable_requestbad,
	dailyreceivabledetail_check,
  dailyreceivable_bad,
	dailyreceivable_incomelist,
  dailypay_list,
  dailypay_saveall,
  dailypay_delete,
  dailypay_approvecheck,
  dailypay_approvesave,
  dailypay_pay,
  dailypay_paysave,
  dailypaydetail_list,
  dailypaydetail_save,
  dailypaydetail_input,
  dailypaydetail_delete,
  dailypay_requestbad,
	dailypaydetail_check,
  dailypay_bad,
  summary_list,
  baddebt_list,
  performance_chengkao,
  performance_chengkaopersonal,
  performance_market,
  performance_kefu,
  studentfeeback_list,
  studentfeeback_approvecheck,
  studentfeeback_approvesave,
  studentfeeback_export,
	studentfeeback_exportfeeback,
  studentfeeback_exportexcel,
  studentfeeback_exportsave,
  techperformance_meiyuzhaosheng,
  studentmoneybackrecv_canbatchmoneybackrecv,
  studentmoneybackrecv_input,
  studentmoneybackrecv_save,
  studentmoneybackrecv_inputbatch,
  studentmoneybackrecv_savebatch,
  studentmoneybackrecv_list,
  studentfeeback_delete,
  studentregisterfeedetail_list,
  studentregisterfeedetail_show,
  studentregisterfeedetail_save,
  studentregisterfeedetail_delete,
  studentregisterfeedetail_feeback,
  moneyintype2_getmoneyintype2byname,
  studentfeeback_savesturegisterfeeback,
  studentregisterfeedetail_canfeeback,
  studentregisterfeedetail_registerfee,
  studentregisterfeedetail_exportdetail,
  studentregisterfeedetail_updateotherfee,
  studentregisterfeedetail_saveregisterfee,
	student_exportstudent,
  studentregisterfeedetail_paytoschoolmoney,
  studentregisterfeedetail_updatediscountfee,
  studentregisterfeedetail_updateextrasubmoney,
  studentregisterfeedetail_checkprint,
  studentregisterfeedetail_print,
	studentregisterfeedetail_printshoujuPaper,
	studentregisterfeedetail_checkstudentname,
	discount_loaddiscount,
	dailypay_paylist,
	discount_export,
	studentregisterfeedetail_input,
    studentregister_export1,
    studentregister_export2,
    studentfudao_export1,
    studentregisterfeedetail_importpay,
}
